import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { add } from '../../context/users/UserActions'
import { useUserContext } from '../../context/users/UserContext'

// eslint-disable-next-line
export default function (props) {
  const EMAIL_REGEX = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/
  const navigate = useNavigate()
  const { authenticatedUser, setAlert } = useUserContext()

  const [userEmail, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(true)
  // const [password, setPass] = useState('')
  // const [confirmPassword, setConfirmPass] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [country, setCountry] = useState('')
  
  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
    if(e.target.value === '') {
      setValidEmail(true)
    } else {
      setValidEmail(EMAIL_REGEX.test(e.target.value))
    }
  }

  // const handleChangePass = (e) => setPass(e.target.value)
  // const handleChangeConfirmPass = (e) => setConfirmPass(e.target.value)
  const handleChangeFirstName = (e) => setFirstName(e.target.value)
  const handleChangeLastName = (e) => setLastName(e.target.value)
  const handleChangeCountry = (e) => setCountry(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if(!validEmail) {
      setAlert({
        message: 'Por favor ingresa el correo electrónico con formato válido.',
        msgType: 'warning'
      })
    } else if(firstName === '' || lastName === '') {
      setAlert({
        message: 'Por favor ingresa todos los datos requeridos',
        msgType: 'warning'
      })
    } else {
      // dispatch({ type: 'SET_LOADING' })

      let userData = await add({ path: 'users',
      data: {
        userEmail: userEmail.trim(),
        firstName,
        lastName,
        country,
        roles: ['ADMIN'],
      }, 
      token: authenticatedUser.value })

      if(Object.prototype.hasOwnProperty.call(userData, 'token')) {
        // authenticateUser(userData)
        navigate('/usuarios')
      } else {
        setAlert({
          message: userData.message,
          msgType: 'warning'
        })
      }
    }
  }
  
  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })

  return (
    <>
      <div className=''>
        <form onSubmit={handleSubmit} className=''>
          <div className='Auth-form-content'>
            <div className='form-group text-center'>
              <h2>Registro de Usuario</h2>
            </div>

            <div className='form-group mt-3'>
              <label>Email usuario: *</label>
              <input
                type='email'
                className='form-control mt-1'
                placeholder='Email usuario'
                value={userEmail}
                onChange={handleChangeEmail}
              />
            </div>
            {!validEmail && (
              <label className='mt-2 text-lg text-danger'>Correo con formato inválido</label>
            )}

            {/* <div className='form-group mt-3'>
              <label>Contraseña: *</label>
              <input
                type='password'
                className='form-control mt-1'
                placeholder='Contraseña'
                value={password}
                onChange={handleChangePass}
              />
            </div>

            <div className='form-group mt-3'>
              <label>Confirmación Contraseña: *</label>
              <input
                type='password'
                className='form-control mt-1'
                placeholder='Confirmación Contraseña'
                value={confirmPassword}
                onChange={handleChangeConfirmPass}
              />
            </div> */}

            <div className='form-group mt-3'>
              <label>Nombre: *</label>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='Nombre'
                value={firstName}
                onChange={handleChangeFirstName}
              />
            </div>

            <div className='form-group mt-3'>
              <label>Apellidos: *</label>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='Apellidos'
                value={lastName}
                onChange={handleChangeLastName}
              />
            </div>

            <div className='form-group mt-3'>
              <label>País de residencia: *</label>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='País de residencia'
                value={country}
                onChange={handleChangeCountry}
              />
            </div>

            <div className='d-grid gap-2 mt-5'>
              <button type='submit' className='btn btn-primary btn-block mb-3'>
                REGISTRAR
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
