import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { searchPA/*, patchByIdPA*/ } from '../../context/users/UserActions'
import { useUserContext } from '../../context/users/UserContext'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FaRegCopy } from 'react-icons/fa'

// eslint-disable-next-line
export default function (props) {
  const navigate = useNavigate()
  const { users: sessions, authenticatedUser, setUsers, setActiveSessions, setAlert } = useUserContext()

  const [keywords, setKeywords] = useState('')
  const handleChangeKeywords = (e) => setKeywords(e.target.value)

  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })

  const handleSearchSession = async (e) => {
    e.preventDefault()

    const response = await searchPA({ path: 'sessions', keywords, token: authenticatedUser.value })
    setUsers(response.data)
  }

  // const handleStatus = async (sessionId, status, index) => {
  //   let record = await patchByIdPA({ path: 'sessions',
  //     id: sessionId,
  //     data: {
  //       active: !status
  //     }, 
  //     token: authenticatedUser.value })

  //     if(Object.prototype.hasOwnProperty.call(record, 'sessionId')) {
  //       sessions[index].active = !status
  //       setUsers(sessions)
  //       setAlert({
  //         message: `Sesión ${status ? 'Desactivada' : 'Activada'} exitosamente`,
  //         msgType: 'success'
  //       })
  //     } else {
  //       setAlert({
  //         message: record.message,
  //         msgType: 'warning'
  //       })
  //     }
  // }

  // const handleDelete = async (sessionId) => {
  //   let record = await patchByIdPA({ path: 'sessions',
  //     id: sessionId,
  //     data: {
  //       deleted: true,
  //     }, 
  //     token: authenticatedUser.value })

  //   if(Object.prototype.hasOwnProperty.call(record, 'sessionId')) {
  //     setUsers(sessions.filter((session) => session.sessionId !== sessionId ))
  //     setAlert({
  //       message: `Sesión borrada exitosamente`,
  //       msgType: 'success'
  //     })
  //   } else {
  //     setAlert({
  //       message: record.message,
  //       msgType: 'warning'
  //     })
  //   }
  // }

  const fetchData = async () => {
    const response = await searchPA({ path: 'sessions', token: authenticatedUser.value })
    if (response.error || !Array.isArray(response.data)) {
      setUsers([])
      setAlert({
        message: response.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.',
        msgType: 'warning'
      })
    } else {
      setUsers(response.data)
      setActiveSessions(response.activeSession)
      // setAlert({
      //   message: 'El sistema por el momento no graba las sesiones.',
      //   msgType: 'warning'
      // })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className='gap-1 mt-3'>
        <div className='container'>
          <div className='row'>
            <div className='col-2'>
              <h5 className='card-title'>Sesiones</h5>
            </div>
            <div className='col-4'>
              <button onClick={() => navigate(`/session`)} type='submit' className='btn btn-primary btn-block mb-3'>
                + Nueva Sesión
              </button>
            </div>
            <div className='col-1'>
            </div>
            <div className='col-4'>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='Buscar'
                value={keywords}
                onChange={handleChangeKeywords}
              />
            </div>
            <div className='col-1 d-flex flex-column align-items-end'>
              <button type='submit' onClick={handleSearchSession} className='btn btn-primary btn-block mb-3'>
                Buscar
              </button>
            </div>
          </div>
          <table className='table table-hover table-bordered'>
            <thead>
              <tr>
                <th scope='col'>Id</th>
                <th scope='col'>Nombre</th>
                <th scope='col'>Estado</th>
                <th scope='col'>Código para invitados</th>
                <th scope='col'>Max. Invitados</th>
                <th scope='col'>Fecha</th>
                <th scope='col'>Descripción</th>
                
                {/* <th scope='col'></th> */}
              </tr>
            </thead>
            <tbody>
              {sessions.map((session, index) => (
                <tr key={session.sessionId}>
                  <th scope='row'>{session.sessionId}</th>
                  <td>{session.active ? <Link to={`${window.location.origin}/sesion/${session.sessionCode}`} target='_blank'>{session.sessionName}</Link> : session.sessionName || '-'}</td>
                  {/* <td><Link to={`/sessions/${session.sessionId}`}>{session.sessionName}</Link></td> */}
                  <td>{session.active ? 'Activa' : 'Inactiva'}</td>
                  <td>
                    {session.active ? (
                      <>
                        {session.sessionCode}
    
                        <CopyToClipboard text={`${window.location.origin}/sesion/${session.sessionCode}`}
                          onCopy={() => setAlert({ message: `Enlace a compartir con invitados: ${window.location.origin}/sesion/${session.sessionCode}`,  msgType: 'success' })}>
                          
                          <button
                            className='btn' style={{ 'padding-top': '0px' }} 
                            data-bs-toggle="tooltip"  data-bs-placement="top"
                            title="Da click aquí para copiar el código a enviar a tus invitados">
                              <FaRegCopy className='amount-icon' />
                          </button>
                        </CopyToClipboard>
                      </>
                    ) : (
                      session.sessionCode
                    )}
                  </td>
                  {/* <td>{session.active ? <Link to={`${window.location.origin}/sesion/${session.sessionCode}`}>{session.sessionCode}</Link> : session.sessionCode || '-'}</td> */}
                  <td>{session.sessionMax || '-'}</td>
                  <td>{session.sessionDate || '-'}</td>
                  <td>{session.description}</td>
                  
                  {/* <td>
                    <div className='form-check form-switch'>
                      <input className='form-check-input'
                        type='checkbox' role='switch'
                        id='flexSwitchCheckDefault' checked={session.active}
                        onChange={() => handleStatus(session.sessionId, session.active, index)}/>
                      <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>{session.active ? 'Activo' : 'Inactivo'}</label>
                    </div>
                  </td>
                  <td>
                    <button
                      onClick={() => handleDelete(session.sessionId)}
                      className='btn'
                      data-bs-title='This top tooltip is themed via CSS variables.'>
                        <FaCopy className='amount-icon' />
                    </button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
