import Modal from 'react-bootstrap/Modal'
import React from 'react'

export default function VideoTutorial({ showModalVideo, handleClose }) {

	return (
		<Modal className='modal-font' show={showModalVideo} onHide={handleClose} size='lg'>
			<Modal.Header closeButton>
				<Modal.Title>Video Tutorial</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
					<iframe width="700" height="394" src="https://www.youtube.com/embed/B4Egtl7eSCM?si=RS3zdN-_3M7-W6rm" title="Video Tutorial Develemos" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
				</div>
			</Modal.Body>
			<Modal.Footer>
			</Modal.Footer>
		</Modal>
	)
}
