import React from 'react'
import Logo from '../components/layout/assets/logo.png'
import { Card } from 'react-bootstrap'

// eslint-disable-next-line
export default function (props) {
  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })

  return (
    <>
      <div className='text-center hero-content text-3xl card-title pt-0 pb-0'>
        <div className='w-100 xl:w-80 lg:w-80 md:w-80 sd:w-80'> 
          <img
            className='text-center mx-auto'
            src={Logo}
            alt='Cargando...'
          />
          <div className='container'>
          <Card style={{ width: '50%' }} className='text-center mx-auto'>
            <Card.Body>
              <p className='text-800'>
                Bienvenido! Te registraste con éxito.<br />
                Te enviamos un correo para que pruebes tu primera sesión.<br />
              </p>
            </Card.Body>
          </Card>
        </div>
        </div>
      </div>
    </>
  )
}
