import React, { /*useState,*/ useEffect } from 'react'
import { /*useNavigate,*/ Link } from 'react-router-dom';
import { getByIdPA /*, patchByIdPA*/ } from '../../context/users/UserActions'
import { useUserContext } from '../../context/users/UserContext'
// import { FaTimes } from 'react-icons/fa';

// eslint-disable-next-line
export default function (props) {
  // const navigate = useNavigate()
  const { users: licenses, authenticatedUser, setUsers, setAlert } = useUserContext()

  // const [keywords, setKeywords] = useState('')
  // const handleChangeKeywords = (e) => setKeywords(e.target.value)

  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })

  // const handleSearchPlan = async (e) => {
  //   e.preventDefault()

  //   const response = await getByIdPA({ path: 'plan', id: authenticatedUser.userId, token: authenticatedUser.value })
  //   setUsers(response.planLicenses)
  // }

  // const handleStatus = async (membershipId, status, index) => {
  //   let record = await patchByIdPA({ path: 'plan',
  //     id: membershipId,
  //     data: {
  //       active: !status
  //     }, 
  //     token: authenticatedUser.value })

  //     if(Object.prototype.hasOwnProperty.call(record, 'membershipId')) {
  //       licenses[index].active = !status
  //       setUsers(licenses)
  //       setAlert({
  //         message: `Licensia ${status ? 'Desactivada' : 'Activada'} exitosamente`,
  //         msgType: 'success'
  //       })
  //     } else {
  //       setAlert({
  //         message: record.message,
  //         msgType: 'warning'
  //       })
  //     }
  // }

  // const handleDelete = async (planId) => {
  //   let record = await patchByIdPA({ path: 'plans',
  //     id: planId,
  //     data: {
  //       deleted: true,
  //     }, 
  //     token: authenticatedUser.value })

  //   if(Object.prototype.hasOwnProperty.call(record, 'planId')) {
  //     setUsers(licenses.filter((license) => license.planId !== planId ))
  //     setAlert({
  //       message: `Licensia borrado exitosamente`,
  //       msgType: 'success'
  //     })
  //   } else {
  //     setAlert({
  //       message: record.message,
  //       msgType: 'warning'
  //     })
  //   }
  // }

  const fetchData = async () => {
    const response = await getByIdPA({ path: 'plan', id: authenticatedUser.userId, token: authenticatedUser.value })
    if (response.error || !Array.isArray(response.planLicenses)) {
      setUsers([])
      setAlert({
        message: response.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.',
        msgType: 'warning'
      })
    } else {
      setUsers(response.planLicenses)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className='gap-1 mt-3'>
        <div className='container'>
          <div className='form-group text-center'>
            <h2>Licencias</h2>
          </div>
          {/* <div className='row'>
            <div className='col-2'>
              <h5 className='card-title'>Licencias</h5>
            </div>
            <div className='col-4'>
              <button onClick={() => navigate(`/plan`)} type='submit' className='btn btn-primary btn-block mb-3'>
                + Agregar Plan
              </button>
            </div>
            <div className='col-1'>
            </div>
            <div className='col-4'>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='Buscar'
                value={keywords}
                onChange={handleChangeKeywords}
              />
            </div>
            <div className='col-1 d-flex flex-column align-items-end'>
              <button type='submit' onClick={handleSearchPlan} className='btn btn-primary btn-block mb-3'>
                Buscar
              </button>
            </div>
          </div> */}
          <table className='table table-hover table-bordered'>
            <thead>
              <tr>
                <th scope='col'>Licencia</th>
                <th scope='col'>Estatus</th>
                <th scope='col'>Correo</th>
                <th scope='col'>Usuario</th>
                <th scope='col'>Tipo de Licencia</th>
                {/* <th scope='col'>Estado</th> */}
              </tr>
            </thead>
            <tbody>
              {licenses.map((license, index) => (
                <tr key={index}>
                  {license.used && license.userId !== license.ownerId ?
                    (<th scope='row'><Link to={`/licencias/${license.membershipId}`}>{license.licenseCode}</Link></th>) :
                    (<th scope='row'>{license.licenseCode}</th>)
                  }
                  <td>{license.used ? 'Usado' : 'Disponible'}</td>
                  <td>{license.userEmail || '-'}</td>
                  <td>{license.fullName || '-'}</td>
                  <td>{license.userRol || '-'}</td>
                  {/* <td>
                    <div className='form-check form-switch'>
                      <input className='form-check-input'
                        type='checkbox' role='switch'
                        id='flexSwitchCheckDefault' checked={license.active}
                        onChange={() => handleStatus(license.membershipId, license.active, index)}/>
                      <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>{license.active ? 'Activo' : 'Inactivo'}</label>
                    </div>
                  </td> */}
                  {/* <td>
                    <button
                      onClick={() => handleDelete(license.membershipId)}
                      className='btn'
                      data-bs-title='This top tooltip is themed via CSS variables.'>
                        <FaTimes className='amount-icon' />
                    </button>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
