import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useUserContext } from '../../context/users/UserContext'
import Logo from "./assets/icon.png"

function Navbar({ title }) {
  const { isAuthenticated, authenticatedUser } = useUserContext()
  const [isVisiable, setIsVisiable] = useState(true)

  useEffect(() => {
    // setIsVisiable(!window.location.pathname.includes('/sesion/'))
  }, [])

  return (
    <nav className='navbar navbar-expand-lg navbar-light bg-white'>
      {(isAuthenticated && !authenticatedUser.pendingPlan && isVisiable) && (<>
        <div className='container-fluid'>
          <div className='collapse navbar-collapse ' id='navbarSupportedContent'>
            <div className='collapse navbar-collapse container' id='navbarSupportedContent'>
              <div>
                <a className='navbar-brand mt-2 mt-lg-0' href='/'>
                  <img
                      src={Logo}
                      alt='Loading...'
                      width="200px"
                      className={""}
                  />
                </a>
              </div>

              {/*<ul className='navbar-nav me-auto mb-2 mb-lg-0'>*/}
              {/*  <li className='nav-item'>*/}
              {/*    <a className='nav-link' href='/'>{title}</a>*/}
              {/*  </li>*/}

              {/*  /!* {validatePermissions(authenticatedUser.permissions, 'users') && (*/}
              {/* <li className='nav-item'>*/}
              {/*  <a className='nav-link' href='/usuarios'>Users</a>*/}
              {/*</li>*/}
              {/*)}*/}

              {/*<li className='nav-item'>*/}
              {/*  <a className='nav-link' onClick={handleSingOut}>Salir</a>*/}
              {/*</li> *!/*/}
              {/*</ul>*/}
            </div>
          </div>

          <div className='d-flex align-items-center'>
            {/* <div className='dropdown'>
                <img
                  src='https://mdbcdn.b-cdn.net/img/new/avatars/2.webp'
                  className='rounded-circle'
                  height='25'
                  alt='Black and White Portrait of a Man'
                  loading='lazy'
                />
            </div> */}
          </div>
        </div>
      </>)}
    </nav>
  )
}

Navbar.defaultProps = {
  title: 'Develemos'
}

Navbar.propTypes = {
  title: PropTypes.string,
}

export default Navbar
