import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { searchPA, currencyFormat, addPA } from '../../context/users/UserActions'
import { useUserContext } from '../../context/users/UserContext'

// eslint-disable-next-line
export default function (props) {
  const navigate = useNavigate()
  const { users: plans, isAuthenticated, authenticateUser, setToken, authenticatedUser, setUsers, setAlert } = useUserContext()

  const [licensesNumber, setLicensesNumber] = useState(1)
  const [selectedPlan, setSelectedPlan] = useState(0)

  const handleChangeSelectedPlan = (planId) => setSelectedPlan(planId)
  const handleChangeLicensesNumber = (e) => setLicensesNumber(e.target.value)

  const handleSentRequest = async (licensesNumber, selectedPlan) => {
    if(parseInt(licensesNumber, 10) <= 0 || selectedPlan === 0) {
      setAlert({
        message: 'Por favor ingresa todos los datos requeridos',
        msgType: 'warning'
      })
    } else {
      let userData = await addPA({ path: 'memberships',
      data: {
        userId: authenticateUser.userId,
        licensesNumber: parseInt(licensesNumber, 10),
        planId: selectedPlan,
      }, 
      token: authenticatedUser.value })

      if(Object.prototype.hasOwnProperty.call(userData, 'planId')) {
        userData = {
          ...authenticatedUser,
          pendingPlan: false,
          sessionCode: false,
        }
        authenticateUser(userData)
        setToken(authenticateUser.value)
        navigate('/home')
      } else {
        setAlert({
          message: userData.message,
          msgType: 'warning'
        })
      }
    }
  }

  const fetchData = async () => {
    const response = await searchPA({ path: 'plans', token: authenticatedUser.value, pageSize: 100 })
    if (response.error || !Array.isArray(response.data)) {
      setUsers([])
      if(isAuthenticated) {
        setAlert({
          message: response.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.',
          msgType: 'warning'
        })
      }
    } else {
      setUsers(response.data)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      {(isAuthenticated) ? (<>
        <div className='gap-1 mt-3'>
          <div className='container'>
            <div className='form-group text-center'>
              <h2>Selección de Plan y Licencias</h2>
            </div>
            <div className='row text-center'>
              {plans.map((plan) => (
                plan.planId === selectedPlan ? (
                  <div className='card col-3 m-4 text-bg-secondary border-dark' key={plan.planId}>
                    <div className='card-body'>
                      <h5 className='card-title'>{plan.planName}</h5>
                      <p className='card-text'>Invitados: {plan.maxGuests}</p>
                      <p className='card-text'>{plan.description}</p>
                      <p className='card-text text-center'>{currencyFormat({ number: plan.price })}</p>
                      <button type='submit' disabled={true} className='btn btn-primary text-cente' data-bs-toggle='button' onClick={() => handleChangeSelectedPlan(plan.planId)}>
                        Seleccionar
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className='card col-3 m-4' key={plan.planId}>
                    <div className='card-body'>
                      <h5 className='card-title'>{plan.planName}</h5>
                      <p className='card-text'>Invitados: {plan.maxGuests}</p>
                      <p className='card-text'>{plan.description}</p>
                      <p className='card-text text-center'>{currencyFormat({ number: plan.price })}</p>
                      <button type='submit' className='btn btn-primary text-cente' data-bs-toggle='button' onClick={() => handleChangeSelectedPlan(plan.planId)}>
                        Seleccionar
                      </button>
                    </div>
                  </div>
                )
              ))}
            </div>

            <div className='form-group mt-3'>
              <label>Número de licencias totales: *</label>
              <input
                type='number'
                className='form-control mt-1'
                placeholder='Número de licencias totales'
                value={licensesNumber}
                onChange={handleChangeLicensesNumber}
              />
            </div>

            <div className='d-grid gap-2 mt-5'>
              <button onClick={() => handleSentRequest(licensesNumber, selectedPlan)} className='btn btn-primary btn-block mb-3'>
                PROCEDER AL PAGO
              </button>
            </div>
          </div>
        </div>
      </>) : (
        navigate('/login')
      )}
      
    </>
  )
}
