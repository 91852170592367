import React, { useState, useEffect } from 'react'
import { useUserContext } from '../../context/users/UserContext'
import {FaPen, FaTimes} from 'react-icons/fa';
import {
    categoryHaveFilesRelated,
    deleteCategory,
    getCategories,
    updateCategory
} from "../../context/photos/PhotoActions";

// eslint-disable-next-line
export default function Category(props) {
    const { setAlert } = useUserContext()

    const [categories, setCategories] = useState([])

    window.scrollTo({
        top: 0,
        behavior: 'auto',
    })

    useEffect(() => {
        const getPictures = async () => {
            const categoryResponse = await getCategories();
            const categoriesWithEditing = categoryResponse.map(category => ({
                ...category,
                editing: false // Agregar la propiedad "editing" con valor inicial false
            }));
            setCategories(categoriesWithEditing);
        }

        getPictures()
    }, [])


    // const handleSearch = async (e) => {
    //     const value = e.target.value
    //     if(value.length > 2 ){
    //         const categoryResponse = await getCategories(value)
    //         setCategories(categoryResponse)
    //     }else if(value.length === 0 ){
    //         const categoryResponse = await getCategories();
    //         setCategories(categoryResponse)
    //     }
    // }

    const handleChangeCategoryName = async (id, e) => {
        const value = e.target.value
        // console.log(value)
        if(value.length > 2 ){
            const response = await updateCategory(id, value);
            if(Object.prototype.hasOwnProperty.call(response, 'response')) {
                setAlert({
                    message: `Categoría actualizada exitosamente`,
                    msgType: 'success'
                })

                const categoryResponse = await getCategories();
                setCategories(categoryResponse)
            } else {
                setAlert({
                    message: response.error || response,
                    msgType: 'warning'
                })
            }
        }
    }

    const handleCategoryDelete = async (id) => {

        let hasFilesRelated = await categoryHaveFilesRelated(id);

        if(hasFilesRelated){
            setAlert({
                message: 'No se puede borrar la categoría porque tiene fotografías asociadas. ',
                msgType: 'warning'
            })
            return;
        }

        let response = await deleteCategory(id)

        if(Object.prototype.hasOwnProperty.call(response, 'response')) {
            setAlert({
                message: `Categoria borrada exitosamente`,
                msgType: 'success'
            })
            const categoryResponse = await getCategories();
            setCategories(categoryResponse)
        } else {
            setAlert({
                message: response.error || response,
                msgType: 'warning'
            })
        }

        const categoryResponse = await getCategories();
        setCategories(categoryResponse)
    }

    const handleEditCategory = (categoryId) => {
        const updatedCategories = categories.map(category => {
            if (category.id === categoryId) {
                return { ...category, editing: true }; // Establecer la propiedad "editing" de la categoría actual en true
            } else {
                return { ...category, editing: false }; // Restablecer la propiedad "editing" de otras categorías a false
            }
        });

        setCategories(updatedCategories);
    };

    return (
        <>
            <div className='gap-1 mt-3'>
                <div className='container'>

                    <div className='mb-10' style={{
                        display : 'flex',
                        justifyContent: 'space-between',
                        margin : '0 0 10px 0'
                    }}>

                        <div className='mb-10'>
                            <h5 className='card-title'>Categorías</h5>
                        </div>

                        {/*<div className='mb-10'>*/}
                        {/*    <input*/}
                        {/*        type='text'*/}
                        {/*        className='form-control mt-1'*/}
                        {/*        placeholder='Buscar'*/}
                        {/*        onChange={handleSearch}*/}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>

                    <table className='table  table-bordered'>
                        <thead>
                        <tr>
                            <th scope='col'>Nombre</th>
                            <th scope='col'></th>
                            <th scope='col'></th> {/* Nueva columna para el ícono de editar */}
                        </tr>
                        </thead>
                        <tbody>
                        {categories.length > 0 && categories.map((category, index) => (
                            <tr key={category.id}>
                                <td>
                                    <input
                                        className={`w-full noBorder ${category.editing ? 'editing-border' : ''}`}
                                        style={{'width': '100%'}}
                                        type='text'
                                        defaultValue={category.categoryName}
                                        placeholder='Buscar o agregar categoría'
                                        disabled={!category.editing}
                                        onBlur={(e) => handleChangeCategoryName(category.id, e)}
                                    />
                                </td>
                                <td> {/* Nueva columna para el ícono de editar */}
                                    <button
                                        onClick={() => handleEditCategory(category.id)}
                                        className='btn'
                                        data-bs-title='Editar categoría'>
                                        <FaPen className='amount-icon' />
                                    </button>
                                </td>
                                <td>
                                    <button
                                        onClick={() => handleCategoryDelete(category.id)}
                                        className='btn'
                                        data-bs-title='This top tooltip is themed via CSS variables.'>
                                        <FaTimes className='amount-icon' />
                                    </button>
                                </td>

                            </tr>
                        ))}
                        </tbody>
                    </table>

                </div>
            </div>
        </>
    )
}
