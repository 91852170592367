import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { getByIdPA, patchByIdPA } from '../../context/users/UserActions'
import { useUserContext } from '../../context/users/UserContext'

// eslint-disable-next-line
export default function (props) {
  const navigate = useNavigate()
  const { authenticatedUser, setAlert } = useUserContext()
  const params = useParams()

  const [planName, setPlanName] = useState('')
  const [price, setPrice] = useState('')
  const [maxGuests, setMaxGuests] = useState('')
  const [months, setMonths] = useState('')
  const [description, setDescription] = useState('')

  const handleChangePlanName = (e) => setPlanName(e.target.value)
  const handleChangePrice = (e) => setPrice(e.target.value)
  const handleChangeMaxGuests = (e) => setMaxGuests(e.target.value)
  const handleChangeMonths = (e) => setMonths(e.target.value)
  const handleChangeDescription = (e) => setDescription(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()
    let record = await patchByIdPA({ path: 'plans',
      id: params.id,
      data: {
        planName: planName.trim(),
        price: parseFloat(price),
        maxGuests: parseInt(maxGuests),
        months: parseInt(months),
        description: description || '',
      }, 
      token: authenticatedUser.value })

    if(Object.prototype.hasOwnProperty.call(record, 'planId')) {
      navigate(`/planes`)
    } else {
      setAlert({
        message: record.message,
        msgType: 'warning'
      })
    }
  }

  const fetchData = async () => {
    const plan = await getByIdPA({ path: 'plans', id: params.id, token: authenticatedUser.value })
    setPlanName(plan.planName)
    setPrice(plan.price)
    setMaxGuests(plan.maxGuests)
    setMonths(plan.months)
    setDescription(plan.description)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className='Auth-form-container'>
        <form onSubmit={handleSubmit} className='Auth-form'>
          <div className='Auth-form-content'>
            <div className='form-group text-center'>
              <h2>Actualizar de Plan</h2>
            </div>

            <div className='form-group mt-3'>
              <label>Nombre del Plan: *</label>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='Nombre del Plan'
                value={planName}
                onChange={handleChangePlanName}
              />
            </div>

            <div className='form-group mt-3'>
              <label>Precio: *</label>
              <input
                type='number'
                className='form-control mt-1'
                placeholder='Precio'
                value={price}
                onChange={handleChangePrice}
              />
            </div>

            <div className='form-group mt-3'>
              <label>Max. Invitados: *</label>
              <input
                type='number'
                className='form-control mt-1'
                placeholder='Max. Invitados'
                value={maxGuests}
                onChange={handleChangeMaxGuests}
              />
            </div>

            <div className='form-group mt-3'>
              <label>Meses: *</label>
              <input
                type='number'
                className='form-control mt-1'
                placeholder='Meses'
                value={months}
                onChange={handleChangeMonths}
              />
            </div>

            <div className='form-group mt-3'>
              <label>Descripción: *</label>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='Descripción'
                value={description}
                onChange={handleChangeDescription}
              />
            </div>

            <div className='d-grid gap-2 mt-5'>
              <button type='submit' className='btn btn-primary btn-block mb-3'>
                ACTUALIZAR
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
