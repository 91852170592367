import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { search, patchById } from '../../context/users/UserActions'
import { useUserContext } from '../../context/users/UserContext'
import { FaTimes } from 'react-icons/fa';

// eslint-disable-next-line
export default function (props) {
  const navigate = useNavigate()
  const { users, authenticatedUser, setUsers, setAlert } = useUserContext()
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)

  const [keywords, setKeywords] = useState('')
  const handleChangeKeywords = (e) => setKeywords(e.target.value)

  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })

  const nextPage = () => {
    if(hasMore){
      setPage(page + 1)
    }
  }

  const prevPage = () => {
      if(page > 1 ){
        setPage(page - 1)
      }
  }

  const handleSearchUser = async (e) => {
    e.preventDefault()

    const response = await search({ path: 'users', keywords, pageSize: 3, token: authenticatedUser.value })
    setHasMore(response.paging.hasMore)
    setUsers(response.data)
  }

  const handleUserStatus = async (userId, status, index) => {
    let userData = await patchById({ path: 'users',
      id: userId,
      data: {
        active: !status
      }, 
      token: authenticatedUser.value })

      if(Object.prototype.hasOwnProperty.call(userData, 'userId')) {
        users[index].active = !status
        setUsers(users)
        setAlert({
          message: `Usuario ${status ? 'Desactivado' : 'Activado'} exitosamente`,
          msgType: 'success'
        })
      } else {
        setAlert({
          message: userData.message,
          msgType: 'warning'
        })
      }
  }

  const handleUserDelete = async (userEmail, userId) => {
    let userData = await patchById({ path: 'users',
      id: userId,
      data: {
        userEmail: `${userEmail}_DELETED-ON-${Date.now()}`,
        deleted: true,
      }, 
      token: authenticatedUser.value })

      if(Object.prototype.hasOwnProperty.call(userData, 'userId')) {
        setUsers(users.filter((user) => user.userId !== userId ))
        setAlert({
          message: `Usuario borrado exitosamente`,
          msgType: 'success'
        })
      } else {
        setAlert({
          message: userData.message,
          msgType: 'warning'
        })
      }
  }

  useEffect(() => {
    const getUsers = async () => {
      const response = await search({ path: 'users', keywords, page, token: authenticatedUser.value })
      setHasMore(response.paging.hasMore)
      setUsers(response.data)
    }

    getUsers()
  }, [page])

  return (
    <>
      <div className='gap-1 mt-3'>
        <div className='container'>
          <div className='row'>
            <div className='col-2'>
              <h5 className='card-title'>Usuarios</h5>
            </div>
            <div className='col-4'>
              <button onClick={() => navigate(`/usuario`)} type='submit' className='btn btn-primary btn-block mb-3'>
                + Agregar Administrador
              </button>
            </div>
            <div className='col-1'>
            </div>
            <div className='col-4'>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='Buscar'
                value={keywords}
                onChange={handleChangeKeywords}
              />
            </div>
            <div className='col-1 d-flex flex-column align-items-end'>
              <button type='submit' onClick={handleSearchUser} className='btn btn-primary btn-block mb-3'>
                Buscar
              </button>
            </div>
          </div>

          <table className='table table-hover table-bordered'>
            <thead>
              <tr>
                <th scope='col'>Id</th>
                <th scope='col'>Nombre</th>
                <th scope='col'>Correo</th>
                <th scope='col'>País</th>
                <th scope='col'>Rol</th>
                <th scope='col'>Estado</th>
                <th scope='col'>Registro</th>
                <th scope='col'></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={user.userId}>
                  <th scope='row'>{user.userId}</th>
                  <td><Link to={`/usuarios/${user.userId}`}>{user.fullName}</Link></td>
                  <td>{user.userEmail}</td>
                  <td>{user.country || '-'}</td>
                  <td>{user.role}</td>
                  <td>
                    <div className='form-check form-switch'>
                      <input className='form-check-input toggle-btn'
                        type='checkbox' role='switch'
                        id='flexSwitchCheckDefault' checked={user.active}
                        onChange={() => handleUserStatus(user.userId, user.active, index)}/>
                      <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>{user.active ? 'Activo' : 'Inactivo'}</label>
                    </div>
                  </td>
                  <td>{user.createdOn}</td>
                  <td>
                    <button
                      onClick={() => handleUserDelete(user.userEmail, user.userId)}
                      className='btn'
                      data-bs-title='This top tooltip is themed via CSS variables.'>
                        <FaTimes className='amount-icon' />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <nav aria-label='Page navigation example' className={'d-flex justify-content-end title-primary'}>
            <ul className='pagination'>
              {page > 1 ? (
                <li className='page-item' onClick={prevPage}>
                  <a className='page-link title-primary fs-3' aria-label='Previous'>
                    <span aria-hidden='true'>&laquo;</span>
                    <span className='sr-only'>Previous</span>
                  </a>
                </li>
              ) : (
                <li className='page-item' onClick={prevPage}>
                  <a className='page-link title-disable fs-3' aria-label='Previous'>
                    <span aria-hidden='true'>&laquo;</span>
                    <span className='sr-only'>Previous</span>
                  </a>
                </li>
              )}

              {hasMore ? (
                <li className='page-item ' onClick={nextPage}>
                  <a className='page-link title-primary fs-3' aria-label='Next'>
                    <span aria-hidden='true'>&raquo;</span>
                    <span className='sr-only'>Next</span>
                  </a>
                </li>
              ) : (
                <li className='page-item ' onClick={nextPage}>
                  <a className='page-link title-disable fs-3' aria-label='Next'>
                    <span aria-hidden='true'>&raquo;</span>
                    <span className='sr-only'>Next</span>
                  </a>
                </li>
              )}
            </ul>
          </nav>
        </div>
      </div>
    </>
  )
}
