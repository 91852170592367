import React, {useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import { useUserContext } from '../../context/users/UserContext'
import { getQuestionById, updateQuestion} from "../../context/questions/QuestionActions";

// eslint-disable-next-line
export default function UpdateQuestions(props) {
  const navigate = useNavigate()
  const { authenticatedUser, setAlert } = useUserContext()

  const params = useParams()

  const [question, setQuestion] = useState('')
  const [description, setDescription] = useState('')
  const [type, setType] = useState('')

  useEffect(() => {

    const getData = async () => {

      const response = await getQuestionById({ path: 'questions',
        id: params.id,
        token: authenticatedUser.value })

      setQuestion(response.question)
      setDescription(response.description)
      setType(response.type)

    }
    getData()

  }, []);

  
  const handleChangeQuestion = (e) => setQuestion(e.target.value)

  const handleChangeDescription = (e) => setDescription(e.target.value)
  // const handleChangeType = (e) => setType(e.target.value)


  const handleSubmit = async (e) => {
    e.preventDefault()

   if(question === '' || description === '') {
      setAlert({
        message: 'Por favor ingresa todos los datos requeridos',
        msgType: 'warning'
      })
    } else {
      // dispatch({ type: 'SET_LOADING' })

      let response = await updateQuestion({ path: 'questions',
        id: params.id,
        data: {
          question,
          description,
          type
        },
        token: authenticatedUser.value })
      if(response.status === 200) {
        navigate('/preguntas')
      } else {
        setAlert({
          message: response.message,
          msgType: 'warning'
        })
      }
    }
  }
  
  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })

  return (
    <>
      <div className=''>
        <form onSubmit={handleSubmit} className=''>
          <div className='Auth-form-content'>
            <div className='form-group text-center'>
              <h2>Actualizar Pregunta</h2>
            </div>

            <div className='form-group mt-3'>
              <label>Pregunta: *</label>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='Pregunta'
                value={question}
                onChange={handleChangeQuestion}
              />
            </div>

            <div className='form-group mt-3'>
              <label>Descripción: *</label>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='Descripcion'
                value={description}
                onChange={handleChangeDescription}
              />
            </div>

            {/*<div className='form-group mt-3'>*/}
            {/*  <label>Tipo: </label>*/}
            {/*  <input*/}
            {/*    type='text'*/}
            {/*    className='form-control mt-1'*/}
            {/*    placeholder='Typo'*/}
            {/*    value={type}*/}
            {/*    onChange={handleChangeType}*/}
            {/*  />*/}
            {/*</div>*/}

            <div className='d-grid gap-2 mt-5'>
              <button type='submit' className='btn btn-primary btn-block mb-3'>
                REGISTRAR
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
