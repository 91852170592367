import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { add } from '../../context/users/UserActions'
import { useUserContext } from '../../context/users/UserContext'
import {createQuestion} from "../../context/questions/QuestionActions";

// eslint-disable-next-line
export default function AddQuestions(props) {
  const navigate = useNavigate()
  const { authenticateUser, authenticatedUser, setAlert } = useUserContext()

  const [question, setQuestion] = useState('')
  const [description, setDescription] = useState('')
  const [type, setType] = useState('')
  
  const handleChangeQuestion = (e) => setQuestion(e.target.value)
  const handleChangeDescription = (e) => setDescription(e.target.value)
  const handleChangeType = (e) => setType(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()

   if(question === '' || description === '') {
      setAlert({
        message: 'Por favor ingresa todos los datos requeridos',
        msgType: 'warning'
      })
    } else {
      // dispatch({ type: 'SET_LOADING' })

      let response = await createQuestion({ path: 'questions',
      data: {
        question,
        description,
        type
      }, 
      token: authenticatedUser.value })
      if(response.status === 200) {
        navigate('/preguntas')
      } else {
        setAlert({
          message: response.message,
          msgType: 'warning'
        })
      }
    }
  }
  
  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })

  return (
    <>
      <div className=''>
        <form onSubmit={handleSubmit} className=''>
          <div className='Auth-form-content'>
            <div className='form-group text-center'>
              <h2>Agregar Pregunta</h2>
            </div>

            <div className='form-group mt-3'>
              <label>Pregunta: *</label>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='Pregunta'
                value={question}
                onChange={handleChangeQuestion}
              />
            </div>

            <div className='form-group mt-3'>
              <label>Descripción: *</label>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='Descripción'
                value={description}
                onChange={handleChangeDescription}
              />
            </div>

            {/*<div className='form-group mt-3'>*/}
            {/*  <label>Tipo: </label>*/}
            {/*  <input*/}
            {/*    type='text'*/}
            {/*    className='form-control mt-1'*/}
            {/*    placeholder='Tipo'*/}
            {/*    value={type}*/}
            {/*    onChange={handleChangeType}*/}
            {/*  />*/}
            {/*</div>*/}

            <div className='d-grid gap-2 mt-5'>
              <button type='submit' className='btn btn-primary btn-block mb-3'>
                REGISTRAR
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
