import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getById } from '../../context/users/UserActions'
import { useUserContext } from '../../context/users/UserContext'
import { Card } from 'react-bootstrap'

// eslint-disable-next-line
export default function (props) {
  const { user, authenticatedUser, setUser } = useUserContext()
  const params = useParams()

  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })

  const fetchData = async () => {
    const response = await getById({ path: 'users', id: params.id, token: authenticatedUser.value })
    setUser(response)
  }

  const {
    // useId,
    fullName,
    userEmail,
    // userPermissions,
    role,
    country,
    organization,
    // industryId,
    specialty,
    position,
    comment,
    licenseCode,
    // createdBy,
    createdOn,
    // updatedBy,
    // updatedOn,
    } = user

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className='gap-1 mt-3'>
        <div className='container'>
          <Card style={{ width: '30%' }}>
            <Card.Body>
              <Card.Title>{fullName}</Card.Title>
              <Card.Subtitle className='mb-2 text-muted'>{role}</Card.Subtitle>
              <p className='text-800'>
                {userEmail && (<><b>Correo:</b> {userEmail}<br /></>)}
                {country && (<><b>País:</b> {country}<br /></>)}
                {organization && (<><b>Organización:</b> {organization}<br /></>)}
                {position && (<><b>Cargo:</b> {position}<br /></>)}
                {comment && (<><b>Descripción:</b> {comment}<br /></>)}
                {licenseCode && (<><b>Licencia:</b> {licenseCode}<br /></>)}
                {specialty && (<><b>Área de especialidad:</b> {specialty}<br /></>)}
                {createdOn && (<><b>Fecha de Registro:</b> {createdOn}<br /></>)}
              </p>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  )
}
