import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getByIdPA } from '../../context/users/UserActions'
import { useUserContext } from '../../context/users/UserContext'
import { Card } from 'react-bootstrap'

// eslint-disable-next-line
export default function (props) {
  const { user: session, authenticatedUser, setUser } = useUserContext()
  const params = useParams()

  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })

  const fetchData = async () => {
    const response = await getByIdPA({ path: 'sessions', id: params.id, token: authenticatedUser.value })
    setUser(response)
  }
  const {
    // sessionId,
    sessionName,
    sessionCode,
    sessionMax,
    sessionDate,
    description,
    active,
    // createdBy,
    createdOn,
    // updatedBy,
    // updatedOn,
    } = session

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className='gap-1 mt-3'>
        <div className='container'>
          <Card style={{ width: '30%' }}>
            <Card.Body>
              <Card.Title>{sessionName}</Card.Title>
              <Card.Subtitle className='mb-2 text-muted'>{description}</Card.Subtitle>
              <p className='text-800'>
                {sessionCode && (<><b>Código:</b> {sessionCode}<br /></>)}
                {sessionMax && (<><b>Max. Invitados:</b> {sessionMax}<br /></>)}
                {sessionDate && (<><b>Fecha:</b> {sessionDate}<br /></>)}
                {description && (<><b>Descripción:</b> {description}<br /></>)}
                <b>Sesión:</b> {active ? 'Activa' : 'Inactiva'}<br />
                {createdOn && (<><b>Fecha de Creación:</b> {createdOn}<br /></>)}
              </p>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  )
}
