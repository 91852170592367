import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../../context/users/UserContext'
import { FaTimes } from 'react-icons/fa';
import {countFiles, getPhotos, removePhoto} from "../../context/photos/PhotoActions";
import { validatePermissions } from '../../context/users/UserActions'
import {hasRecords} from "../../context/questions/QuestionActions";

// todo:
// eliminar datos innecesarios de la db


// eslint-disable-next-line
export default function Photos(props) {
    const navigate = useNavigate()
    const { authenticatedUser, setAlert } = useUserContext()
    const [pag, setPag] = useState(0);
    const [maxNextPage, setMaxNextPage] = useState(0);
    const [photos, setPhotos] = useState([])
    const [categoryName, setCategoryName] = useState('');

    window.scrollTo({
        top: 0,
        behavior: 'auto',
    })

    useEffect(() => {
        const getPictures = async () => {
            if (categoryName.length > 2) {
                const { files, totalPages } = await getPhotos(1, categoryName, pag);
                setPhotos(files);
                setMaxNextPage(totalPages);
            } else {
                const { files, totalPages } = await getPhotos(1, '', pag);
                setPhotos(files);
                setMaxNextPage(totalPages);
            }
        };

        getPictures();
    }, [pag, categoryName]);

    const nextPage = () => {
        if((pag + 1) < maxNextPage){
            setPag(pag + 1)
        }
    }

    const prevPage = () => {
        if(pag !== 0 ){
            setPag(pag - 1)
        }
    }

    const handleSearch = async (e) => {
        const value = e.target.value
        setCategoryName(value);
        if(value.length > 2 ){
            const phts = await getPhotos(1, value)
            // getCountFileByCategory()
            setPhotos(phts)
        }else if(value.length === 0 ){
            const phts = await getPhotos(1)
            // getCountFile()
            setPhotos(phts)
        }
    }

    const handleUserDelete = async (id, fileName) => {

        let hasRecord = await hasRecords({ path: 'file',
            fileId: fileName,
            token: authenticatedUser.value
        })

        if(hasRecord.hasRecords && hasRecord.noRecords > 0) {
            setAlert({
                message: "No se puede borrar la imagen porque tiene registros asociados",
                msgType: 'warning'
            })
            return;
        }

        let response = await removePhoto(fileName)

        if(Object.prototype.hasOwnProperty.call(response, 'response')) {
            setAlert({
                message: `Imagen borrada exitosamente`,
                msgType: 'success'
            })
            const phts = await getPhotos(1);
            setPhotos(phts)
        } else {
            setAlert({
                message: response.message,
                msgType: 'warning'
            })
        }
    }

    return (
        <>
            <div className='gap-1 mt-3'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-2'>
                            <h5 className='card-title'>Fotografías</h5>
                        </div>
                        <div className='col-3'>
                            <button onClick={() => navigate(`/fotografias/agregar`)} type='submit' className='btn btn-primary btn-block mb-3'>
                                + Agregar Fotografía
                            </button>
                        </div>
                        <div className='col-3'>
                            <button onClick={() => navigate(`/categorias`)} type='submit' className='btn btn-primary btn-block mb-3'>
                                Catálogo de Categorías
                            </button>
                        </div>
                        <div className='col-3'>
                            <input
                                type='text'
                                className='form-control mt-1'
                                placeholder='Buscar'
                                onChange={handleSearch}
                            />
                        </div>
                        <div className='col-1 d-flex flex-column align-items-end'>

                        </div>
                    </div>
                    <table className='table table-hover table-bordered'>
                        <thead>
                        <tr>
                            <th scope='col'>Id</th>
                            <th scope='col'>Fotografía</th>
                            <th scope='col'>Categoría</th>
                            {!validatePermissions(authenticatedUser.permissions, 'photos'  ) ? (
                                <th scope='col'></th>
                            ) : (<></>)}
                        </tr>
                        </thead>
                        <tbody>

                        {photos && photos.length > 0 && photos.map((photo, index) => (
                            <tr key={photo.id}>
                                <th scope='row'>{photo.id}</th>
                                <td >
                                    <a href={photo.uri} target="_blank" rel="noopener noreferrer" >
                                        <div className={`mb-3 flex`} style={{display: 'flex'}}>
                                            <img
                                                className='text-center mx-auto  '
                                                src={`${window.location.origin}/file/api/v1${photo.uri}`}
                                                alt='Loading...'
                                                width='150'
                                            />
                                        </div>
                                    </a>
                                </td>
                                <td>{photo.category.categoryName}</td>
                                {!validatePermissions(authenticatedUser.permissions, 'photos'  ) ? (
                                    <td>
                                        <button
                                            onClick={() => handleUserDelete(photo.id, photo.fileName)}
                                            className='btn'
                                            data-bs-title='This top tooltip is themed via CSS variables.'>
                                            <FaTimes className='amount-icon' />
                                        </button>
                                    </td>
                                ) : (<></>)}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <nav aria-label="Page navigation example" className="d-flex justify-content-end title-primary">
                        <ul className="pagination">
                            {/* Botón "Previous", se deshabilita si estás en la primera página */}
                            <li className={`page-item ${pag === 0 ? 'disabled' : ''}`} onClick={prevPage}>
                                <a className="page-link title-primary fs-3" aria-label="Previous">
                                    <span aria-hidden="true">&laquo;</span>
                                    <span className="sr-only">Previous</span>
                                </a>
                            </li>

                            {/* Botón "Next", se deshabilita si estás en la última página */}
                            <li className={`page-item ${pag + 1 === maxNextPage ? 'disabled' : ''}`} onClick={nextPage}>
                                <a className="page-link title-primary fs-3" aria-label="Next">
                                    <span aria-hidden="true">&raquo;</span>
                                    <span className="sr-only">Next</span>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </>
    )
}
