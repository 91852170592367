import React, { useState, useEffect } from 'react'
import * as XLSX from 'xlsx'
import { useUserContext } from '../../context/users/UserContext'
import { FaFileExcel } from 'react-icons/fa'
import { searchPA } from '../../context/users/UserActions'

// eslint-disable-next-line
export default function SessionReports() {
	const [startDate, setStartDate] = useState()
	const [endDate, setEndDate] = useState()
	const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
	const [users, setUsers] = useState()
	const { authenticatedUser } = useUserContext()

	const handleChangeStartDate = async (e) => {
		setStartDate(e.target.value)
		if(endDate) {
			const response = await searchPA({ path: 'sessionReport', page, startDate: e.target.value, endDate, token: authenticatedUser.value })
      setHasMore(response.paging.hasMore)
			setUsers(response.data)
		}
	}

	const handleChangeEndDate = async (e) => {
		setEndDate(e.target.value)
		if(startDate) {
			const response = await searchPA({ path: 'sessionReport', page, startDate, endDate: e.target.value, token: authenticatedUser.value })
      setHasMore(response.paging.hasMore)
			setUsers(response.data)
		}
	}

	const nextPage = () => {
    if(hasMore){
      setPage(page + 1)
    }
  }

  const prevPage = () => {
      if(page > 1 ){
        setPage(page - 1)
      }
  }

	window.scrollTo({
		top: 0,
		behavior: 'auto',
	})

	useEffect(() => {
    const getSession = async () => {
      const response = await searchPA({ path: 'sessionReport', page, startDate, endDate, token: authenticatedUser.value })
      setHasMore(response.paging.hasMore)
			setUsers(response.data)
    }

    getSession()
  }, [page])

	const handleGenerateReport = async () => {
		let result = []
		if (users.length > 0) {
			result = users.map((user) => ({
				'User ID': user.userId,
				'Nombre': user.firstName,
				'Apellido': user.lastName,
				'Mail': user.userEmail,
				'País': user.country,
				'Fecha de Registro': user.registrationDate,
				'Plan': user.plan,
				'Cantidad de Sesiones': user.noSessions,
				'Invitados Acumulados': user.noGuest
			}))
		}

    const worksheet = XLSX.utils.json_to_sheet(result)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Reporte')
    XLSX.writeFile(workbook, 'Reporte.xlsx', { compression: true })
	}

	return (
		<>
			<div className='gap-1 mt-3'>
				<div className='container'>

					<div className='row'>
						<div className='col-12'>
							<h5 className='card-title '>Reportes de Sesiones</h5>
						</div>
					</div>

					<div className='container mt-3'>
						<div className='row justify-content-left'>
								<div className='col-lg-3 col-sm-6'>
										<label htmlFor='startDate'>Fecha Inicial</label>
										<input
											id='startDate'
											className='form-control'
											value={startDate}
											max={endDate}
											onChange={handleChangeStartDate}
											type='date' />
										<span id='startDateSelected'></span>
								</div>
								<div className='col-lg-3 col-sm-6'>
										<label htmlFor='endDate'>Fecha Final</label>
										<input
											id='endDate'
											className='form-control'
											value={endDate}
											min={startDate}
											onChange={handleChangeEndDate}
											type='date' />
										<span id='endDateSelected'></span>
								</div>
								<div className='col-lg-3 col-sm-6'>
									<div className='col-1 ColumnStyle'>
										<button
											onClick={() => handleGenerateReport()}
											className='btn mt-4'>
											<FaFileExcel />
										</button>
									</div>
								</div>
						</div>
				</div>

					<table className='table table-hover table-bordered mt-5'>
						<thead>
							<tr>
								<th scope='col'>User ID</th>
								<th scope='col'>Nombre</th>
								<th scope='col'>Apellido</th>
								<th scope='col'>Mail</th>
								<th scope='col'>País</th>
								<th scope='col'>Fecha de Registro</th>
								<th scope='col'>Plan</th>
								<th scope='col'>Cantidad de Sesiones</th>
								<th scope='col'>Invitados Acumulados</th>
							</tr>
						</thead>
						<tbody>
							{users && users.length > 0 && users.map((user) => (
								<tr key={user.userId}>
									<td>{user.userId}</td>
									<td>{user.firstName}</td>
									<td>{user.lastName}</td>
									<td>{user.userEmail}</td>
									<td>{user.country}</td>
									<td>{user.registrationDate}</td>
									<td>{user.plan}</td>
									<td>{user.noSessions}</td>
									<td>{user.noGuest}</td>
								</tr>
							))}
						</tbody>
					</table>

					<nav aria-label='Page navigation example' className={'d-flex justify-content-end title-primary'}>
            <ul className='pagination'>
              {page > 1 ? (
                <li className='page-item' onClick={prevPage}>
                  <a className='page-link title-primary fs-3' aria-label='Previous'>
                    <span aria-hidden='true'>&laquo;</span>
                    <span className='sr-only'>Previous</span>
                  </a>
                </li>
              ) : (
                <li className='page-item' onClick={prevPage}>
                  <a className='page-link title-disable fs-3' aria-label='Previous'>
                    <span aria-hidden='true'>&laquo;</span>
                    <span className='sr-only'>Previous</span>
                  </a>
                </li>
              )}

              {hasMore ? (
                <li className='page-item ' onClick={nextPage}>
                  <a className='page-link title-primary fs-3' aria-label='Next'>
                    <span aria-hidden='true'>&raquo;</span>
                    <span className='sr-only'>Next</span>
                  </a>
                </li>
              ) : (
                <li className='page-item ' onClick={nextPage}>
                  <a className='page-link title-disable fs-3' aria-label='Next'>
                    <span aria-hidden='true'>&raquo;</span>
                    <span className='sr-only'>Next</span>
                  </a>
                </li>
              )}
            </ul>
          </nav>
				</div>
			</div>
		</>
	)
}
