import {getDomain} from "../context/users/UserActions";

export function getFileName(filename) {
    return filename.replace("/downloadFile/", "")
}

export function obtenerDominio() {
    var partes = window.location.hostname.split('.');
    return partes.slice(-2).join('.');
}

// Función para obtener el valor de una cookie por su nombre
function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

export const handleGetReport = async ()  => {
    try {
        const token = getCookie('keyToken'); // Obtener el token desde la cookie
        var currentProtocol = window.location.protocol;
        var currentDomain = getDomain();
        var encuestaURL = currentProtocol + '//encuesta.' + currentDomain + '/survey/api/v1/get_user_answers_report?surveyId=1';

        // Realiza la solicitud GET usando fetch
        const response = await fetch(encuestaURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Authorization': `Bearer ${token}`
            }
        });

        // Si la respuesta es exitosa
        if (response.status === 200) {
            // Convierte la respuesta en un blob
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            // Crea un enlace temporal para descargar el archivo
            const link = document.createElement('a');
            link.href = url;
            link.download = 'respuestas.xlsx';  // Nombre del archivo que se descargará
            link.click();

            // Libera el objeto URL creado
            window.URL.revokeObjectURL(url);
        } else {
            console.error('Error al obtener el archivo', response.status);
        }
    } catch (error) {
        console.error('Error en la solicitud', error);
    }
};

export const NUM_ROWS_DEFAULT = 25;
