import { Navigate } from 'react-router-dom'

import { useUserContext } from '../context/users/UserContext'
 
const AuthenticatedRoute = ({ children }) => {
    const { isAuthenticated, authenticatedUser } = useUserContext()
 
    if (isAuthenticated) {
      if (authenticatedUser.pendingPlan) {
        return <Navigate to='/seleccionaPlan'></Navigate>
      }
        return children
    } 

  return <Navigate to='/login'></Navigate>
}
 
export default AuthenticatedRoute
