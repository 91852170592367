import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { searchPA, currencyFormat, patchByIdPA } from '../../context/users/UserActions'
import { useUserContext } from '../../context/users/UserContext'
import { FaTimes } from 'react-icons/fa';

// eslint-disable-next-line
export default function (props) {
  const navigate = useNavigate()
  const { users: plans, authenticatedUser, setUsers, setAlert } = useUserContext()

  const [keywords, setKeywords] = useState('')
  const handleChangeKeywords = (e) => setKeywords(e.target.value)

  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })

  const handleSearchPlan = async (e) => {
    e.preventDefault()

    const response = await searchPA({ path: 'plans', keywords, token: authenticatedUser.value })
    setUsers(response.data)
  }


  const handleStatus = async (planId, status, index) => {
    let record = await patchByIdPA({ path: 'plans',
      id: planId,
      data: {
        active: !status
      }, 
      token: authenticatedUser.value })

      if(Object.prototype.hasOwnProperty.call(record, 'planId')) {
        plans[index].active = !status
        setUsers(plans)
        setAlert({
          message: `Plan ${status ? 'Desactivado' : 'Activado'} exitosamente`,
          msgType: 'success'
        })
      } else {
        setAlert({
          message: record.message,
          msgType: 'warning'
        })
      }
  }

  const handleDelete = async (planId, hasMemberships) => {
    if(!hasMemberships) {
      let record = await patchByIdPA({ path: 'plans',
        id: planId,
        data: {
          deleted: true,
        }, 
        token: authenticatedUser.value })

      if(Object.prototype.hasOwnProperty.call(record, 'planId')) {
        setUsers(plans.filter((plan) => plan.planId !== planId ))
        setAlert({
          message: `Plan borrado exitosamente`,
          msgType: 'success'
        })
      } else {
        setAlert({
          message: record.message,
          msgType: 'warning'
        })
      }
    } else {
      setAlert({
        message: 'Este plan no se puede eliminar porque tiene registros asociados',
        msgType: 'warning'
      })
    }
  }

  const fetchData = async () => {
    const response = await searchPA({ path: 'plans', token: authenticatedUser.value })
    if (response.error || !Array.isArray(response.data)) {
      setUsers([])
      setAlert({
        message: response.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.',
        msgType: 'warning'
      })
    } else {
      setUsers(response.data)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className='gap-1 mt-3'>
        <div className='container'>
          <div className='row'>
            <div className='col-2'>
              <h5 className='card-title'>Planes</h5>
            </div>
            <div className='col-4'>
              <button onClick={() => navigate(`/plan`)} type='submit' className='btn btn-primary btn-block mb-3'>
                + Agregar Plan
              </button>
            </div>
            <div className='col-1'>
            </div>
            <div className='col-4'>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='Buscar'
                value={keywords}
                onChange={handleChangeKeywords}
              />
            </div>
            <div className='col-1 d-flex flex-column align-items-end'>
              <button type='submit' onClick={handleSearchPlan} className='btn btn-primary btn-block mb-3'>
                Buscar
              </button>
            </div>
          </div>
          <table className='table table-hover table-bordered'>
            <thead>
              <tr>
                <th scope='col'>Id</th>
                <th scope='col'>Nombre</th>
                <th scope='col'>Precio</th>
                <th scope='col'>Max. Invitados</th>
                <th scope='col'>Meses</th>
                <th scope='col'>Descripción</th>
                <th scope='col'>Estado</th>
                <th scope='col'></th>
              </tr>
            </thead>
            <tbody>
              {plans.map((plan, index) => (
                <tr key={plan.planId}>
                  <th scope='row'>{plan.planId}</th>
                  <td><Link to={`/planes/${plan.planId}`}>{plan.planName}</Link></td>
                  <td>{currencyFormat({ number: plan.price })}</td>
                  <td>{plan.maxGuests || '-'}</td>
                  <td>{plan.months}</td>
                  <td>{plan.description}</td>
                  <td>
                    <div className='form-check form-switch'>
                      <input className='form-check-input toggle-btn'
                        type='checkbox' role='switch '
                        id='flexSwitchCheckDefault' checked={plan.active}
                        onChange={() => handleStatus(plan.planId, plan.active, index)}/>
                      <label className='form-check-label' htmlFor='flexSwitchCheckDefault'>{plan.active ? 'Activo' : 'Inactivo'}</label>
                    </div>
                  </td>
                  <td>
                    <button
                      onClick={() => handleDelete(plan.planId, plan.hasMemberships)}
                      className='btn'
                      data-bs-title='This top tooltip is themed via CSS variables.'>
                        <FaTimes className='amount-icon' />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
