import axios from 'axios'
// const userServiceUrl = process.env.REACT_APP_USER_SERVICE || 'http://70.35.204.186:3000/pjd-alleanza-user'
const host = process.env.REACT_APP_HOST || window.location.origin
// const userServiceUrl = `${host}${process.env.REACT_APP_USER_SERVICE || '/user'}`
const service = `${host}${process.env.REACT_APP_SUBS_SERVICE || '/plan'}`


// console.log(service)
// const service = 'http://localhost:80/file/api/v1/'


const db = axios.create({
  baseURL: service,
})

export const getQuestions = async ({ path, keywords = '', pageSize = 25, page = 1, token, sortBy = '' })  => {
  try {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    const params = new URLSearchParams({
      keywords,
      pageSize,
      page,
    })

    const response = await db.get(`/${path}?${params}`, config)
    return response.data
  } catch (error) {
    const response = {
      error: true,
      message: error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    }
    return response
  }
}

// Create Question
export const createQuestion = async ({ path, data, token }) => {
  try {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    if(path) {
      const response = await db.post(`/${path}`, data, config)
      // console.log({data: response?.data?.data,
      //   status: response.status})
      return {
        data: response?.data?.data,
        status: response.status
    } ||
      {
        data: response.data
      }
    } else {
      return false
    }
  } catch (error) {
    const response = error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    return response
  }
}

// Get Records by Id
export const getQuestionById = async ({ path, id, token }) => {
  try {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    if(path && id) {
      const response = await db.get(`/${path}/${id}`, config)
      return response?.data?.data || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    } else {
      return {}
    }
  } catch (error) {
    const response = error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    return response
  }
}

export const updateQuestion = async ({ path, id, data, token }) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    if(path && id) {
      let response = await db.patch(`/${path}/${id}`, data, config)
      return {response: response?.data?.data,
            status: response.status
    }||
      {
        response: response.data,
        status: response.status
      }
    } else {
      return true
    }
  } catch (error) {
    const response = error.response.data || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    return response
  }
};


export const removeQuestion = async ({ path, id, token }) => {
  try {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    if(window.confirm('¿Seguro que deseas eliminar esta pregunta del catálogo?')) {
      await db.delete(`/${path}/${id}`, config)
      return true
    }
    return false
  }catch (error) {
    return error
  }
}

export const addQuestionToSession = async ({ path, data, token, sessionId }) => {
  try {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
    console.log(data)

    if(path) {
      const response = await db.post(`/${path}/${sessionId}`, data, config)
      return response?.data?.data || response.data
    } else {
      return false
    }
  } catch (error) {
    const response = error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    return response
  }
}


// Retrieve If image has records attached by id
export const hasRecords = async ({ path, fileId, token }) => {
  try {

    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }

    if(path && fileId) {
      const response = await db.get(`/${path}/${fileId}/hasRecords`, config)
      return response?.data?.data || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    } else {
      return {}
    }
  } catch (error) {
    const response = error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    return response
  }
}


