import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { add, search, getEncryptedPassword } from '../../context/users/UserActions'
import { useUserContext } from '../../context/users/UserContext'
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import Logo2 from '../layout/assets/icon.png'

// eslint-disable-next-line
export default function (props) {
  const EMAIL_REGEX = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/
  const navigate = useNavigate()
  const { uses, authenticateUser, setUses, setAlert } = useUserContext()

  const [userEmail, setEmail] = useState('')
  const [validEmail, setValidEmail] = useState(true)
  const [password, setPass] = useState('')
  const [showPass, setShowPass] = useState(false)
  const [confirmPassword, setConfirmPass] = useState('')
  const [showConfirmPass, setShowConfirmPass] = useState(false)
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [country, setCountry] = useState('')
  const [useId, setUseId] = useState('')

  const [position, setPosition] = useState('')
  const [licenseCode, setLicenseCode] = useState('')
  
  const handleChangeEmail = (e) => {
    setEmail(e.target.value)
    if(e.target.value === '') {
      setValidEmail(true)
    } else {
      setValidEmail(EMAIL_REGEX.test(e.target.value))
    }
  }

  const handleChangePass = (e) => setPass(e.target.value)
  const handleChangeConfirmPass = (e) => setConfirmPass(e.target.value)

  const handleChangeShowPass = (e) => {
    e.preventDefault()
    const type = showPass ? false : true
    setShowPass(type)
  }

  const handleChangeShowConfirmPass = (e) => {
    e.preventDefault()
    const type = showConfirmPass ? false : true
    setShowConfirmPass(type)
  }

  const handleChangeFirstName = (e) => setFirstName(e.target.value)
  const handleChangeLastName = (e) => setLastName(e.target.value)
  const handleChangeCountry = (e) => setCountry(e.target.value)
  const handleChangeUseId = (e) => setUseId(e.target.value)

  const handleChangePosition = (e) => setPosition(e.target.value)
  const handleChangeLicenseCode = (e) => setLicenseCode(e.target.value)

  const handleSubmit = async (e) => {
    e.preventDefault()

    if(!validEmail) {
      setAlert({
        message: 'Por favor ingresa el correo electrónico con formato válido.',
        msgType: 'warning'
      })
    } else if(firstName === '' || password === '') {
      setAlert({
        message: 'Por favor ingresa todos los datos requeridos',
        msgType: 'warning'
      })
    } else {
      // dispatch({ type: 'SET_LOADING' })
      const record = await getEncryptedPassword(password)

      let userData = await add({ path: 'users/registry',
      data: {
        userEmail: userEmail.trim(),
        userPass: record.userPass.trim(),
        firstName,
        lastName,
        country,
        useId: useId,
        position: position,
        licenseCode: licenseCode,
        host: window.location.origin,
        roles: ['ANFITRION'],
      }, 
      token: '' })

      if(Object.prototype.hasOwnProperty.call(userData, 'userId')) {
        userData = {
          ...userData,
          value: userData.token
        }
        // authenticateUser(userData)
        // setToken(userData.token)
        navigate('/welcome')
      } else {
        setAlert({
          message: userData.message || userData,
          msgType: 'warning'
        })
      }
    }
  }

  const fetchData = async () => {
    const response = await search({ path: 'uses', type: 'licencia', token: '' })
    if (response.error) {
      setUses([])
    } else {
      setUses(response.data)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className=''>
          <div className='Auth-form-content'>
            <div id='navbarSupportedContent'>
              <div id='navbarSupportedContent'>
                <div>
                  <a className='navbar-brand mt-2 mt-lg-0' href='/'>
                    <img
                        src={Logo2}
                        alt='Loading...'
                        width='200px'
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div className='Auth-form-container-full'>
        <form onSubmit={handleSubmit} className='Auth-form'>
          <div className='Auth-form-content'>
            <div className='form-group text-center'>
              <h2>Registro de Usuario</h2>
            </div>
            
            {/* <h3 className='Auth-form-title'>Sign In</h3> */}
            <div className='form-group mt-3'>
              <label>Licencia: *</label>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='Licencia'
                value={licenseCode}
                onChange={handleChangeLicenseCode}
              />
            </div>

            <div className='form-group mt-3'>
              <label>Email usuario: *</label>
              <input
                type='email'
                className='form-control mt-1'
                placeholder='Email usuario'
                value={userEmail}
                onChange={handleChangeEmail}
              />
            </div>
            {!validEmail && (
              <label className='mt-2 text-lg text-danger'>Correo con formato inválido</label>
            )}

            <div className='form-group mt-3'>
              <label>Contraseña: *</label>
              <input
                type={showPass ? 'text' : 'password'}
                className='form-control mt-1'
                placeholder='Contraseña'
                value={password}
                onChange={handleChangePass}
              />
              {password && (
                <button className='btn btn btn-light btn-block btn-outline-dark eye-icon' onClick={handleChangeShowPass}>
                  {showPass ? (<FaEyeSlash />) : (<FaEye />)}
                </button>
              )}
            </div>

            <div className='form-group mt-3'>
              <label>Confirmación Contraseña: *</label>
              <input
                type={showConfirmPass ? 'text' : 'password'}
                className='form-control mt-1'
                placeholder='Confirmación Contraseña'
                value={confirmPassword}
                onChange={handleChangeConfirmPass}
              />
              {confirmPassword && (
                <button className='btn btn btn-light btn-block btn-outline-dark eye-icon' onClick={handleChangeShowConfirmPass}>
                  {showConfirmPass ? (<FaEyeSlash />) : (<FaEye />)}
                </button>
              )}
            </div>

            <div className='form-group mt-3'>
              <label>Nombre: *</label>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='Nombre'
                value={firstName}
                onChange={handleChangeFirstName}
              />
            </div>

            <div className='form-group mt-3'>
              <label>Apellidos: *</label>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='Apellidos'
                value={lastName}
                onChange={handleChangeLastName}
              />
            </div>

            <div className='form-group mt-3'>
              <label>Cargo: *</label>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='Cargo'
                value={position}
                onChange={handleChangePosition}
              />
            </div>

            <div className='form-group mt-3'>
              <label>País de residencia: *</label>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='País de residencia'
                value={country}
                onChange={handleChangeCountry}
              />
            </div>

            <div className='form-group mt-3'>
              <label>¿Para qué lo utilizarás?: *</label>
              <select 
                className='form-select mt-1'
                data-choices='data-choices'
                data-options='{"removeItemButton":true,"placeholder":true}'
                value={useId}
                onChange={handleChangeUseId}>
                  <option disabled defaultValue key='' value=''>Selecciona una opción</option>
                  {uses.map((options) => (
                    <option key={options.id} value={options.id}>
                      {options.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className='d-flex flex-column align-items-end mt-5'>
              <button type='submit' className='btn btn-primary btn-block mb-3'>
                REGISTRAR
              </button>
            </div>

            {/* <div className='d-grid gap-2 mt-5'>
              <button type='submit' className='btn btn-primary btn-block mb-3'>
                REGISTRAR
              </button>
            </div> */}
          </div>
        </form>
      </div>
    </>
  )
}
