import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { validateEmail } from '../context/users/UserActions'

// eslint-disable-next-line
export default function (props) {
  const navigate = useNavigate()
  const params = useParams()
  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })

  const fetchData = async () => {
    const response = await validateEmail(params.userToken)
    if (response.error) {
      navigate('/welcome')
    } else {
      navigate('/login')
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className='text-center hero-content text-3xl card-title pt-0 pb-0'>
      </div>
    </>
  )
}
