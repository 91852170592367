import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Manager } from 'socket.io-client'
import { useUserContext } from '../../context/users/UserContext'
import { Card } from 'react-bootstrap'
import { getByIdPA, patchByIdPA, add, addPA, getDomain } from '../../context/users/UserActions'
import { validatePermissions, deleteByIdPA } from '../../context/users/UserActions'
import { addQuestionToSession, createQuestion } from '../../context/questions/QuestionActions'
import Logo from '../../components/layout/assets/logo.png'
import ResponseCanvas from './ResponseCanvas'
import AddQuestionToCanvas from './AddQuestionToCanvas'
import AddPhotoToCanvas from './AddPhototoCanvas'
import VideoTutorial from './VideoTutorial'
import Logo2 from '../layout/assets/icon.png'

const EMAIL_REGEX = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/

// const listenerServiceUrl = `http://alleanzacanvas.${getDomain()}`
const listenerServiceUrl = process.env.REACT_APP_LISTENER_SERVICE || window.location.origin
const manager = new Manager(listenerServiceUrl)
const socket = manager.socket('/')

// const host = process.env.REACT_APP_HOST || window.location.origin
// const userServiceUrl = `${host}${process.env.REACT_APP_USER_SERVICE || '/user'}`
// const service = `${host}${process.env.REACT_APP_IMAGES_SERVICE || '/file/api/v1'}`

// eslint-disable-next-line
export default function (props) {
  const { session, setSession, authenticatedUser, isAuthenticated, authenticateUser, setToken, setAlert } = useUserContext()
  const [validSession, setValidSession] = useState(false)
  const [message, setMessage] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [userIndex, setUserIndex] = useState(0)
  const [questionId, setQuestionId] = useState(0)
  const [questionIndex, setQuestionIndex] = useState(0)
  const [userName, setUsername] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [validEmail, setValidEmail] = useState(true)
  const [sessionOpen, setSessionOpen] = useState(true)

  const [qstResponses, setQuestionResponses] = useState([])
  const [imagesSelected, setImagesSelected] = useState([])
  const [userAnswers, setUserAnswers] = useState([])
  const [hoverImage, setHoverImage] = useState(0)
  const [hoverQuestion, setHoverQuestion] = useState(0)
  const [imageToUpdate, setImageToUpdate] = useState(0)
  const [questionToUpdate, setQuestionToUpdate] = useState(0)
  const [phraseToUpdate, setPhraseToUpdate] = useState('')

  const [show, setShow] = useState(false)
  const [showPhoto, setShowPhoto] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const [newQuestion, setNewQuestion] = useState(false)
  const [question, setQuestion] = useState('')
  const [selectQuestion, setSelectQuestion] = useState(0)
  const [photoIds, setPhotosIds] = useState([])
  const [photoObjs, setPhotosObjs] = useState([])
  const params = useParams()

  const currentSsn = localStorage.getItem('alleanza_currentSession')
  const currentSession = currentSsn ? JSON.parse(currentSsn) : { sessionCode: params.id }
  const [sameSession, setSameSession] = useState(currentSession.sessionCode === params.id)

  const handleChangeEmail = (e) => {
    setUserEmail(e.target.value)
    if (e.target.value === '') {
      setValidEmail(true)
    } else {
      setValidEmail(EMAIL_REGEX.test(e.target.value))
    }
  }

  const handleChangeName = (e) => setUsername(e.target.value)
  const handlePhraseToUpdate = (e) => {
    setPhraseToUpdate(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!validEmail) {
      setAlert({
        message: 'Por favor ingresa el correo electrónico con formato válido.',
        msgType: 'warning'
      })
    } else if (userName === '' || userEmail === '') {
      setAlert({
        message: 'Por favor ingresa todos los datos requeridos',
        msgType: 'warning'
      })
    } else {
      // dispatch({ type: 'SET_LOADING' })

      let userData = await add({
        path: 'guest/registry',
        data: {
          userEmail: userEmail.trim(),
          userName,
          sessionId: params.id
        },
        token: ''
      })

      if (Object.prototype.hasOwnProperty.call(userData, 'token')) {
        const expiry = new Date().getTime() + (4 * 1000 * 60 * 60)
        const data = {
          value: userData.token,
          userId: userData.userId,
          pendingPlan: userData.pendingPlan,
          sessionCode: userData.sessionCode,
          expiry,
          permissions: userData?.permissions || [],
        }
        localStorage.setItem('alleanza_jwtoken', JSON.stringify(data))
        authenticateUser(userData)
        setToken(userData.token)
        window.location.reload()
        setSameSession(true)
        localStorage.setItem('alleanza_currentSession', JSON.stringify({ sessionCode: params.id }))
      } else {
        setAlert({
          message: userData.message,
          msgType: 'warning'
        })
      }
    }
  }

  const handleCloseModal = () => setShowModal(false)
  const handleClose = () => setShow(false)

  // function redirectToEncuesta() {
  //   var currentProtocol = window.location.protocol
  //   var currentDomain = getDomain()
  //   var encuestaURL = currentProtocol + "//encuesta." + currentDomain + "/"
  //   window.open(encuestaURL, "_blank")
  // }

  const handleShow = (questionId, userIndex, questionIndex, canAnswer, responses, userResponses) => {
    if (canAnswer) {
      setQuestionId(questionId)
      setUserIndex(userIndex)
      setQuestionIndex(questionIndex)
      setUserAnswers(userResponses)
      setImagesSelected(userResponses.map((image) => (image.imageId)))
      // Add phrase to responses iterate userResponses and add phrase to responses
      const updatedResponses = responses.map((response) => ({
        ...response,
        phrase: getPhrase(response.imageId, userResponses)
      }))
      setQuestionResponses(updatedResponses)
      setShowModal(true)
    }
  }

  const handleHoverImage = (e, imageId, sessionQstId) => {
    e.preventDefault()
    setHoverImage(imageId)
    setHoverQuestion(sessionQstId)
  }

  const handleHoverOut = (e) => {
    e.preventDefault()
    setHoverImage(0)
    setHoverQuestion(0)
  }

  const handleUpdatePhrase = (e, sessionQstId, imageId, phrase) => {
    e.stopPropagation()
    setImageToUpdate(imageId)
    setQuestionToUpdate(sessionQstId)
    setPhraseToUpdate(phrase)
  }

  const handleUpdatePhraseResponse = async (e, indexQst, index, indexRes) => {
    e.stopPropagation()
    // Update this phrase
    session.sessionUsers[index].userAnswers[indexQst].responses[indexRes].phrase = phraseToUpdate
    setUserAnswers(session.sessionUsers[index].userAnswers[indexQst].responses)
    let record = await patchByIdPA({
      path: 'answers',
      id: session.sessionId || 0,
      data: {
        questionId,
        sessionQstId: session.sessionUsers[index].userAnswers[indexQst].sessionQstId,
        responses: session.sessionUsers[index].userAnswers[indexQst].responses,
      },
      token: authenticatedUser.value || authenticatedUser.token
    })

    if (Object.prototype.hasOwnProperty.call(record, 'sessionId')) {
      // session.sessionUsers[userIndex].userAnswers[questionIndex].responses = userAnswers
      setSession(session)
      // Set update on DB
      socket.emit('Canvas', { session: params.id, type: 'UPDATE_ANSWER', user: session.sessionUsers[userIndex], userId: authenticatedUser.userId })
      setShowModal(false)
    } else {
      setAlert({
        message: record.message,
        msgType: 'warning'
      })
    }
    setUserAnswers([])
    setImagesSelected([])
    setImageToUpdate(0)
  }

  const handleDeleteImage = async (e, indexQst, index, indexRes) => {
    e.stopPropagation()
    session.sessionUsers[index].userAnswers[indexQst].responses.splice(indexRes, 1)
    setUserAnswers(session.sessionUsers[index].userAnswers[indexQst].responses)
    let record = await patchByIdPA({
      path: 'answers',
      id: session.sessionId || 0,
      data: {
        questionId,
        sessionQstId: session.sessionUsers[index].userAnswers[indexQst].sessionQstId,
        responses: session.sessionUsers[index].userAnswers[indexQst].responses,
      },
      token: authenticatedUser.value || authenticatedUser.token
    })

    if (Object.prototype.hasOwnProperty.call(record, 'sessionId')) {
      // session.sessionUsers[userIndex].userAnswers[questionIndex].responses = userAnswers
      setSession(session)
      // Set update on DB
      socket.emit('Canvas', { session: params.id, type: 'UPDATE_ANSWER', user: session.sessionUsers[userIndex], userId: authenticatedUser.userId })
      setShowModal(false)
    } else {
      setAlert({
        message: record.message,
        msgType: 'warning'
      })
    }
    setUserAnswers([])
    setImagesSelected([])
    setImageToUpdate(0)
  }

  const getPhrase = (id, userResponses) => {
    let phrase = ''
    const questionData = userResponses.filter((response) => response.imageId === id)
    if (questionData.length > 0) {
      phrase = questionData[0].phrase
    }
    return phrase
  }

  const onClickSelectPics = async () => {
    // if()
    setShow(!show);
    setShowPhoto(!showPhoto)
    setPhotosIds([])
    setPhotosObjs([])

    if (question.length > 3 && newQuestion) {
      let response = await createQuestion({
        path: 'questions',
        data: {
          question: question,
          "type": "NEW"
        },
        token: authenticatedUser.value
      })

      if (response.status === 200 && response.data) {
        setSelectQuestion(response.data.questionId)
      }
      if (response.status !== 200) {
        setAlert({
          message: response.message,
          msgType: 'warning'
        })
      }

    }
  }

  const handleOnSelectQuestion = (e) => {
    const value = e.target.value
    setSelectQuestion(value)
  }

  const handleChangeQuestion = async (e) => {
    const value = e.target.value;
    if (value.length > 3 && show && newQuestion) {
      setQuestion(value)
    }
  }

  const handleAddNewQuestionToggle = () => {
    setNewQuestion(!newQuestion)
    setQuestion('');
  }


  const saveQuestionToSession = async () => {

    if (photoObjs.length > 0 && selectQuestion !== 0) {
      const data = {
        "questionId": Number(selectQuestion),
        "responses": photoObjs
      }
      const record = await addQuestionToSession({ path: 'sessionQuestion', data, token: authenticatedUser.value, sessionId: session.sessionId });

      if (Object.prototype.hasOwnProperty.call(record, 'questionId')) {
        // Add question to all session users
        let sessionData = await addPA({
          path: `userQuestion/${session.sessionCode}`,
          data: {
            sessionQstId: record.sessionQstId,
            questionId: record.questionId,
            sessionId: session.sessionId,
            sessionCode: session.sessionCode,
          },
          token: authenticatedUser.value
        })

        if (Object.prototype.hasOwnProperty.call(sessionData, 'sessionUsers')) {
          const questionExists = session.questions.some((question) => question.sessionQstId === record.sessionQstId)
          if (!questionExists) {
            session.questions.push(record)
          }
          session.sessionUsers = session.sessionUsers.map((userDetails) => ({
            ...userDetails,
            userAnswers: updateUserQuestions({
              userId: userDetails.userId,
              sessionId: session.sessionId,
              sessionCode: session.sessionCode,
              currentQuestions: userDetails.userAnswers,
              newQuestion: {
                ...record,
                responses: []
              }
            })
          }))
          setSession(session)
          // Set update on DB
          socket.emit('Canvas', { session: params.id, type: 'ADD_QUESTION', question: record, userId: authenticatedUser.userId })
          setShowPhoto(false)
          setPhotosObjs([])
          setPhotosIds([])
          setQuestion('')
        } else {
          setAlert({
            message: sessionData.message,
            msgType: 'warning'
          })
        }
      } else {
        setAlert({
          message: record.message,
          msgType: 'warning'
        })
      }
    }
  }

  const handleOnSelectResponses = ({ imageId, response }) => {
    if (!imagesSelected.includes(imageId)) {
      const answer = {
        sessionQstId: response.sessionQstId,
        imageId: response.imageId,
        image: response.image,
        phrase: ''
      }
      setUserAnswers([...userAnswers, answer])
      setImagesSelected([...imagesSelected, imageId]);
    } else {
      setUserAnswers((prevState) => prevState.filter((answer) => answer.imageId !== imageId));
      setImagesSelected((prevState) => prevState.filter((image) => image !== imageId));
    }
  }

  const handleChangePhrase = async (e, index, imageId) => {
    const phraseTmp = e.target.value
    const temp = userAnswers
    const imgIndex = userAnswers.findIndex((userAnswer) => userAnswer.imageId === imageId)
    temp[imgIndex].phrase = phraseTmp
    setUserAnswers(temp)

    qstResponses[index].phrase = phraseTmp
    setQuestionResponses(qstResponses)
  }

  const updateUserQuestions = ({ userId, sessionId, sessionCode, currentQuestions, newQuestion }) => {
    const result = currentQuestions
    const questionExists = currentQuestions.some((question) => question.sessionQstId === newQuestion.sessionQstId)
    if (!questionExists) {
      result.push({
        sessionId,
        sessionCode,
        userId,
        ...newQuestion,
      })
    }
    return result
  }

  const removeUserQuestions = ({ sessionQstId, currentQuestions }) => {
    let result = currentQuestions
    result = currentQuestions.filter((question) => question.sessionQstId !== sessionQstId)
    return result
  }

  const saveResponse = async () => {
    if (userAnswers.length > 0) {
      let record = await patchByIdPA({
        path: 'answers',
        id: session.sessionId || 0,
        data: {
          questionId,
          sessionQstId: userAnswers[0].sessionQstId,
          responses: userAnswers,
        },
        token: authenticatedUser.value || authenticatedUser.token
      })

      if (Object.prototype.hasOwnProperty.call(record, 'sessionId')) {
        session.sessionUsers[userIndex].userAnswers[questionIndex].responses = userAnswers
        setSession(session)
        // Set update on DB
        socket.emit('Canvas', { session: params.id, type: 'UPDATE_ANSWER', user: session.sessionUsers[userIndex], userId: authenticatedUser.userId })
        setShowModal(false)
      } else {
        setAlert({
          message: record.message,
          msgType: 'warning'
        })
      }
      setUserAnswers([])
      setImagesSelected([])
    } else {
      setAlert({
        message: 'Favor de ingresar todos los campos',
        msgType: 'warning'
      })
    }
  }

  const handleChangeAddQuestions = async () => {
    setShow(true)
  }

  const closeSession = async ({ sessionId }) => {
    const record = await patchByIdPA({
      path: 'sessions',
      id: sessionId,
      data: {
        active: false,
        reportReady: true
      },
      token: authenticatedUser.value
    })
    if (Object.prototype.hasOwnProperty.call(record, 'sessionId')) {
      setSessionOpen(false)
      socket.emit('Canvas', { session: params.id, type: 'CLOSE_SESSION' })
      var currentProtocol = window.location.protocol
      var currentDomain = getDomain()
      var encuestaURL = currentProtocol + '//encuesta.' + currentDomain + '/encuesta/responder/1'
      window.open(encuestaURL, '_self')
    }
    return sessionId
  }

  const handleChangeRemoveQuestion = async (sessionQstId, e) => {
    e.preventDefault()
    if (sessionQstId) {
      const record = await deleteByIdPA({
        path: 'sessionQuestion',
        id: sessionQstId,
        token: authenticatedUser.value,
        message: '¿Seguro que deseas eliminar esta pregunta de la sesión?'
      })

      if (record.deleted) {
        // Remove question from all session users
        if (Object.prototype.hasOwnProperty.call(record, 'id')) {
          const questionExists = session.questions.some((question) => question.sessionQstId === record.id)
          session.questions = session.questions.filter((question) => question.sessionQstId !== record.id)
          if (questionExists) {
            session.sessionUsers = session.sessionUsers.map((userDetails) => ({
              ...userDetails,
              userAnswers: removeUserQuestions({
                sessionQstId: sessionQstId,
                currentQuestions: userDetails.userAnswers,
              })
            }))

            setSession(session)
            // Send Update to other users
            socket.emit('Canvas', { session: params.id, type: 'REMOVE_QUESTION', sessionQstId: record.id, userId: authenticatedUser.userId })
          }
        }
      } else {
        setAlert({
          message: record.message,
          msgType: 'warning'
        })
      }
    }
  }

  const handleEvent = (event) => {
    // Validate if event belongs to current session and session is active
    if (params.id === event.session && session) {
      // Validate if event belongs to current user
      if (event.userId !== authenticatedUser.userId) {
        // Execute actions depending on event type
        switch (event.type) {
          // New user enter to the session
          case 'START_SESSION':
            // Check if user already exits on the session
            const userInSession = session.sessionUsers.some((user) => user.userId === event.user.userId)
            if (!userInSession && event.user.userRole === 'INVITADO') {
              setAlert({
                message: `${event.user.userFullName} se unió a la sesión`,
                msgType: 'success'
              })
              session.sessionUsers.push(event.user)
              setSession(session)
            }
            break

          case 'UPDATE_ANSWER':
            // Check if user is on the session
            const userOnSession = session.sessionUsers.some((user) => user.userId === event.user.userId)
            if (userOnSession) {
              // setAlert({
              //   message: `El usuario: ${event.user.userFullName} respondio a una de las preguntas`,
              //   msgType: 'success'
              // })
              session.sessionUsers = session.sessionUsers.map((user) => ({
                ...user,
                userAnswers: user.userId === event.userId ? event.user.userAnswers : user.userAnswers
              }))
              setSession(session)
            }
            break

          case 'ADD_QUESTION':
            // Check if user is on the session
            const question = event.question
            if (question) {
              setAlert({
                message: `Una pregunta ha sido agregada a la sesión`,
                msgType: 'success'
              })
              const questionExists = session.questions.some((item) => item.sessionQstId === question.sessionQstId)
              if (!questionExists) {
                session.questions.push(question)
              }
              session.sessionUsers = session.sessionUsers.map((userDetails) => ({
                ...userDetails,
                userAnswers: updateUserQuestions({
                  userId: userDetails.userId,
                  sessionId: session.sessionId,
                  sessionCode: session.sessionCode,
                  currentQuestions: userDetails.userAnswers,
                  newQuestion: {
                    ...question,
                    responses: []
                  }
                })
              }))
              setSession(session)
            }
            break

          case 'REMOVE_QUESTION':
            // Check if user is on the session
            const sessionQstId = event.sessionQstId
            if (sessionQstId) {
              setAlert({
                message: `Una pregunta ha sido removida de la sesión`,
                msgType: 'success'
              })
              const questionExists = session.questions.some((item) => item.sessionQstId === sessionQstId)
              session.questions = session.questions.filter((question) => question.sessionQstId !== sessionQstId)

              if (!questionExists) {
                session.sessionUsers = session.sessionUsers.map((userDetails) => ({
                  ...userDetails,
                  userAnswers: removeUserQuestions({
                    sessionQstId,
                    currentQuestions: userDetails.userAnswers,
                  })
                }))
              }
              setSession(session)
            }
            break

          case 'USER_LEFT':
            // Check if user already exits on the session
            const isInSession = session.sessionUsers.some((user) => user.userId === event.user.userId)
            if (isInSession) {
              setAlert({
                message: `El usuario: ${event.user.userFullName} ha abandonado la sesión`,
                msgType: 'warning'
              })
              session.sessionUsers((user) => user.userId === event.user.userId)
              setSession(session)
            }
            break

          case 'CLOSE_SESSION':
            // Check if user is not a host
            if (!validatePermissions(authenticatedUser.permissions, 'isHost')) {
              var currentProtocol = window.location.protocol
              var currentDomain = getDomain()
              var encuestaURL = currentProtocol + '//encuesta.' + currentDomain + '/encuesta/responder/1'
              setAlert({
                message: `El Anfitrión ha finalizado tu sesión. !Gracias por fortalecer las capacidades humanas de sentir e idear, con Develemos!`,
                msgType: 'success',
                link: true,
                url: encuestaURL
              })
              setSessionOpen(false)
            }
            break

          default:
            break
        }
      }
    }
  }

  socket.on('connect', () => {
    // console.log(`Connect ${socket.id}`)
  })

  socket.on('disconnect', () => {
    // console.log(`Disconnect`)
    // if(session) {
    //   socket.emit('Canvas', { session: session.sessionId || 0, type: 'USER_LEFT', user: {}, userId: authenticatedUser.userId})
    //   patchByIdPA({ path: 'canvas',
    //   id: session.sessionId || 0,
    //   data: {
    //     active: false,
    //   },
    //   token: authenticatedUser.value })
    // }
  })

  socket.on('Usuario', (event) => {
    // Event recieved
    handleEvent(event)
  })

  const fetchData = async () => {
    if (sameSession) {
      const session = await getByIdPA({ path: 'canvas', id: params.id, token: authenticatedUser.value || authenticatedUser.token })
      if (Object.prototype.hasOwnProperty.call(session, 'sessionId')) {
        socket.emit('Canvas', { session: params.id, type: 'START_SESSION', user: session.user, userId: authenticatedUser.userId })
        session.sessionUsers = (session?.sessionUsers || []).filter((user) => user.userRole === 'INVITADO')
        setSession(session)
        const isHost = ((authenticatedUser.permissions || []).find((permission) => ['ANFITRION_ORG', 'ANFITRION_PER', 'ANFITRION'].includes(permission))) ? true : false
        const queryParams = new URLSearchParams(window.location.search)
        setShowVideo(isHost && queryParams.get('newSession') === '1')
        setValidSession(true)
        localStorage.setItem('alleanza_currentSession', JSON.stringify(session))
      } else {
        setMessage(session.message || message)
      }
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className='container-fluid'>
        <div className='collapse navbar-collapse ' id='navbarSupportedContent'>
          <div className='collapse navbar-collapse container' id='navbarSupportedContent'>
            <div>
              <a className='navbar-brand mt-2 mt-lg-0' href='/'>
                <img
                    src={Logo2}
                    alt='Loading...'
                    width='200px'
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      {isAuthenticated && sameSession ?
        (validSession ? (
          <div onContextMenu={(e) => e.preventDefault()} className='gap-1 mt-3'>
            {validatePermissions(authenticatedUser.permissions, 'isHost') && (
              <div className='row'>
                <div className='col-10'>
                </div>
                <div className='col-2 d-flex flex-column align-items-end'>
                  <button type='submit' onClick={async () => await closeSession({ sessionId: session.sessionId })} className='btn btn-primary btn-block mb-3 me-3'>
                    <b>Cerrar Sesión</b>
                  </button>
                </div>
              </div>
            )}
            <main style={{ 'marginRight': '12px', 'marginLeft': '60px' }}>
              <div className='table'>
                <table className='table table-hover table-bordered '>
                  <thead>
                    <tr>
                      <th className='form-group text-center tableFont' colSpan={session.sessionUsers.length + 1} scope='col'>{session.sessionName}</th>
                    </tr>
                    <tr>
                      <th key={0} scope='col' className='columnVisible tableFont'></th>
                      {session.sessionUsers.map((user) => (
                        <th key={user.userId} className='form-group text-center headerVisible tableFont' scope='col'>{user.userFullName}</th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {session.questions.map((question, indexQst) => (
                      <tr height='180px' key={question.sessionQstId}>
                        <td className='form-group text-center align-middle columnVisible tableFont'>
                          <b>{question.question}</b><br></br>
                          {validatePermissions(authenticatedUser.permissions, 'isHost') && (
                            <a onClick={async (e) => await handleChangeRemoveQuestion(question.sessionQstId, e)} href='#'>
                              <b>Eliminar Pregunta</b>
                            </a>
                          )}
                        </td>
                        {session.sessionUsers.map((user, index) => (
                          <td
                            className='form-group text-center align-middle'
                            key={user.userId}
                            style={{ 'border': '1px solid black' }}
                            onClick={() => handleShow(question.questionId, index, indexQst, user.userId === authenticatedUser.userId && sessionOpen, question.responses, user?.userAnswers[indexQst]?.responses || [])}>
                            <div className='row RowStyle'>
                              {(user?.userAnswers[indexQst]?.responses || []).length > 0  ? ((user?.userAnswers[indexQst]?.responses || []).map((response, indexRes) => (
                                <div key={indexRes} className='col-4 ColumnStyle' >
                                  <div className="img-wrap" onMouseOver={ (e) => handleHoverImage(e, response.imageId, response.sessionQstId) } onMouseOut={ (e) => handleHoverOut(e) } >
                                    {user.userId === authenticatedUser.userId && sessionOpen && response.imageId === hoverImage && hoverQuestion === response.sessionQstId && (
                                      <i onClick={(e) => handleUpdatePhrase(e, response.sessionQstId, response.imageId, response.phrase)} className='edit fa fa-pencil-alt fa-md side-icon' data-test='fa'></i>
                                    )}
                                    {user.userId === authenticatedUser.userId && sessionOpen && response.imageId === hoverImage && hoverQuestion === response.sessionQstId && (
                                      <i onClick={async (e) => await handleDeleteImage(e, indexQst, index, indexRes)} className='close fa fa-trash fa-md side-icon' data-test='fa'></i>
                                    )}
                                    <img src={response.image} width='150' alt='Image1' />
                                    {/* <img src={'https://static.freeimages.com/images/home/filetypes/photo.webp'} width='150' alt='Image1' /> */}
                                  </div>
                                  {response.imageId === imageToUpdate && questionToUpdate === response.sessionQstId ? (
                                    <div className='form-group mt-3 row' >
                                      <input
                                        type='text'
                                        style={{ 'width': '130px' }}
                                        className='form-control form-control-sm mt-1 col-8'
                                        placeholder='Agrega una frase'
                                        value={phraseToUpdate}
                                        onClick={(e) => { e.stopPropagation() }}
                                        onChange={handlePhraseToUpdate}
                                      />
                                      <i
                                        onClick={async (e) => await handleUpdatePhraseResponse(e, indexQst, index, indexRes)}
                                        className='fa fa-check fa-md side-icon col-3'
                                        style={{ 'paddingTop': '12px', 'color': '#fe850e' }}
                                        data-test='fa'></i>
                                    </div>
                                  ) : (
                                    <p style={{ 'width': '150px' }} className='card-title'>{response.phrase}</p>
                                  )}
                                </div>
                              ))) : ( user.userId === authenticatedUser.userId && sessionOpen ?
                                (<div className='col-1 ColumnStyle' >
                                  <button className='btn btn-primary btn-block mb-3'>
                                    Responder
                                  </button>
                                </div>) : (<></>)
                              )}
                            </div>
                          </td>
                        ))}
                      </tr>
                    ))}

                    {validatePermissions(authenticatedUser.permissions, 'isHost') && (
                      <tr height='180px'>
                        <td className='form-group text-center align-middle columnVisible tableFont' style={{ 'border': '1px solid black' }}>
                          <button onClick={async () => await handleChangeAddQuestions()} style={{ 'border': '1px solid white' }} className='btn btn-primary btn-block mb-3'>
                            <b>Agregar Pregunta</b>
                          </button>
                        </td>
                        {session.sessionUsers.map((user) => (
                          <td key={user.userId} style={{ 'border': '1px solid black' }} />
                        ))}
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </main>

            {validatePermissions(authenticatedUser.permissions, 'isHost') && (
              <div className='gap-1 mt-3'>
                <div className='container'>
                  <Card style={{ width: '100%' }}>
                    <Card.Body>
                      <p className='text-800'>
                        <b>Paso 1:</b>  Haz click en “Agregar Pregunta” y selecciona una del listado. En caso que quieras personalizarla elige la opción de “Crear nueva pregunta”​.<br />
                        <b>Paso 2:</b>  Haz click en “Siguiente" y la plataforma te permitirá seleccionar las fotos que disponibilizarás para que tus usuarios respondan. Tenemos más de 50 fotos agrupadas en diversas categorías! No olvides de revisarlas todas.<br />
                        <b>Paso 3:</b>  Ahora invita a los usuarios a la sesión enviándoles el siguiente enlace: {window.location.origin}/sesion/{params.id}
                      </p>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className='gap-1 mt-3'>
            <div className='container'>
              <div className='row'>
                <div className='col-12' />
              </div>
              <table className='table-responsive table-hover table-bordered'>
                <thead>
                  <tr>
                    <th className='form-group text-center' scope='col'>{message}</th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        )) :
        (<>
          <div className='Auth-form-container-full'>
            <form onSubmit={handleSubmit} className='Auth-form'>
              <div className='Auth-form-content'>
                <div className='form-group mt-3 text-center'>
                  <img
                    src={Logo}
                    alt='Loading...'
                  />
                </div>
                <div className='form-group mt-3'>
                  <label>Invitado:</label>
                  <input
                    type='Nombre'
                    className='form-control mt-1'
                    placeholder='Nombre'
                    value={userName}
                    onChange={handleChangeName}
                  />
                </div>
                <div className='form-group mt-3'>
                  <label>Correo electrónico:</label>
                  <input
                    type='email'
                    className='form-control mt-1'
                    placeholder='Correo electrónico'
                    value={userEmail}
                    onChange={handleChangeEmail}
                  />
                </div>
                <div className='d-grid gap-2 mt-3'>
                  <button type='submit' className='btn btn-primary btn-block mb-3'>
                    Ingresar como invitado
                  </button>
                </div>
              </div>
            </form>
          </div>
        </>)}

      <ResponseCanvas
        showModalResponse={showModal}
        handleClose={handleCloseModal}
        handleSelectResponse={handleOnSelectResponses}
        saveResponse={saveResponse}
        responses={qstResponses}
        imagesSelected={imagesSelected}
        handleChangePhrase={handleChangePhrase}
      />

      <AddQuestionToCanvas
        showModalQuestion={show}
        handleClose={handleClose}
        onClickNext={onClickSelectPics}
        onblurCreateQuestion={handleChangeQuestion}
        newQuestion={newQuestion}
        setNewQuestion={handleAddNewQuestionToggle}
        handleOnSelectQuestion={handleOnSelectQuestion}
      />

      <AddPhotoToCanvas
        showModalPic={showPhoto}
        handleClose={() => setShowPhoto(false)}
        setPhotosObjs={setPhotosObjs}
        saveQuestionToSession={saveQuestionToSession}
      />

      <VideoTutorial
        showModalVideo={showVideo}
        handleClose={() => setShowVideo(false)}
      />
    </>
  )
}
