import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserContext } from '../../context/users/UserContext'
import {addPhotos, getCategories} from "../../context/photos/PhotoActions";

// eslint-disable-next-line
export default function AddPhoto () {

  const navigate = useNavigate()
  const {  setAlert } = useUserContext()
  // const { } = usePhotoContext();

  const [file, setFile] = useState(null)
  const [categories, setCategories] = useState([])
  const [categoryName, setCategoryName] = useState('')

  // Input value and suggestions for dynamic category input
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    const getCate = async () => {

      const category = await getCategories();
      setCategories(category)

    }
    getCate()
  }, [])


  const handleChangeFile= (e) => {
    const f = e.target.files[0];
    if (f) {

      const maxSize =  1024 * 1024; // 1 MB in bytes
      if (f.size > maxSize) {

        setAlert( {
          message: 'Archivo excede el tamaño permitido de 1 MB',
          msgType: 'warning'
        });

        setFile(null);
        return;
      }
      setFile(e.target.files[0]);
    }
  };

  const handleInput = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };

  const handleCategorySelect = (category) => {
    // console.log(category.categoryName)
    setCategoryName(category.categoryName);
    setInputValue(category.categoryName);
  };


  const handleSubmit = async (e) => {
    e.preventDefault()

    if(file === null ) {
      setAlert('Por favor ingresa todos los datos requeridos', 'error')

    } else {

      const formData = new FormData();
      formData.append("file", file);

      formData.append("fileDto", JSON.stringify({
        appId: 1,
        categoryName : inputValue.trim().toLowerCase()
      }));

      // for (const pair of formData.entries()) {
      //   console.log(pair[0], pair[1]);
      // }

      const newFile = await addPhotos(formData)
      //
      // dispatch({ type: 'ADD_FILE', payload: newFile });
      // dispatch({ type: 'GET_FILES', payload: []});

      setFile('');
      setCategoryName('');

      navigate(`/fotografias`);
    }
  }

  return (
    <>
      <div className=''>
        <form onSubmit={handleSubmit} className=''>
          <div className='m-4'>

            <div className='form-group text-center'>
              <h2>Agregar Fotografía</h2>
            </div>

            <div className='  w-full'>
              <label className='mt-4'>
                Fotografía: *
              </label>

              <input
                  className="form-control mt-2 w-full text-lg text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                  id="file_input"
                  type="file"
                  placeholder='file'

                  accept="image/png, image/jpeg"
                  onChange={handleChangeFile} />
            </div>


            <div className=' '>
              <label className='mt-4 text-md '>
                Categoría: *
              </label>
              <input
                  className='form-control mt-2 w-full '
                  type='text'
                  value={inputValue}
                  placeholder='Buscar o agregar categoría'
                  onChange={handleInput}
              />
              {inputValue && (
                  <ul className='absolute left-0 z-10 w-full bg-white border border-gray-300 mt-2 rounded-md shadow-md'>
                    {categories
                        .filter(
                            (category) =>
                                category.categoryName
                                    .toLowerCase()
                                    .includes(inputValue.toLowerCase())
                        )
                        .map((category) => (
                            <li
                                key={category.id}
                                className='p-2 cursor-pointer hover:bg-gray-200'
                                onClick={() => handleCategorySelect(category)}
                            >
                              {category.categoryName}
                            </li>
                        ))}
                  </ul>
              )}
            </div>

            <div className='d-grid gap-2 mt-5'>
              <button type='submit' className='btn btn-primary btn-block mb-3'>
                REGISTRAR
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
