import axios from 'axios'
// import {getReportSession} from "../users/UserActions";
// const userServiceUrl = process.env.REACT_APP_USER_SERVICE || 'http://70.35.204.186:3000/pjd-alleanza-user'
const host = process.env.REACT_APP_HOST || window.location.origin
const service = `${host}${process.env.REACT_APP_IMAGES_SERVICE || '/file/api/v1'}`


// console.log(service)
// const service = 'http://localhost:80/file/api/v1/'


const db = axios.create({
  baseURL: service,
})


// Login Endpoint
export const getPhotos = async (appId, categoryName = '', page = 0 )  => {
  try {
    const response = await db.get(`/all_files`, {
      params: {
        appId,
        categoryName: categoryName.length > 0 ? categoryName : '',
        p: page
      }
    });

    return {
      files: response?.data?.response.fileDto || [], // Si no hay fotos, devuelve un array vacío
      totalPages: response?.data?.response.totalPages // Si no hay totalPages, devuelve 0
    };

  } catch (error) {
    return {
      error: error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    };
  }
}

export const getPhotosByCategory = async ( categoryName = '' , )  => {
  try {
    const response = await db.get(`/all_files_by_category`, {
      params:{
        categoryName : categoryName.length > 0 ? categoryName : ''
      }
    })
    return response?.data?.data || response.data.response


  } catch (error) {
    return error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
  }
}

export const countFiles = async (appId, categoryName = "")  => {
  try {

    const response = await db.get(`/count_files`, {
      params:{
        appId,
        categoryName : categoryName.length > 0 ? categoryName : ''
      }
    })
    return response?.data?.data || response.data.response


  } catch (error) {
    return error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
  }
}


export const addPhotos = async (formData) => {
  try {

    const response = await db.post(`/load_file`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    const photos = response?.data?.data || response.data
    return photos

  } catch (error) {
    const response = error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    return response
  }
}

export const getCategories = async (categoryName = '') => {
  try {

    const response = await db.get(`/categories`,{
      params:{
        categoryName : categoryName.length > 0 ? categoryName : ''
      }
    })

    return response?.data?.data || response.data.response

  } catch (error) {
    return error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
  }
}

export const updateCategory = async (id, categoryName) => {
  try {
    const response = await db.post(`/update_category`, {
      categoryId: id,
      categoryName: categoryName
    });

    return response.data;
  } catch (error) {
    return error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.';
  }
};


export const removePhoto = async (fileName) => {
  try {

    const response = await db.get(`/removeFile/`,  {
      params: {
        'fileCode': fileName
      }
    });

    return response?.data?.data || response.data

  } catch (error) {
    return error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
  }
}


export const deleteCategory = async (id) => {
  try {

    if(window.confirm('¿Seguro que deseas eliminar la categoria? todas las imágenes relacionadas se elimaran tambien') ){

      const response = await db.get(`/delete_category`, {
        params: {
          id
        }
      })
      const photos = response?.data?.data || response.data.response
      console.log(photos);
      return photos
    }
  } catch (error) {
    const response = error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'
    return response
  }
}

export const categoryHaveFilesRelated = async (id) => {
  try {

    const response = await db.get(`/category_have_files`, {
      params: {
        id
      }
    })
    return response?.data?.data || response.data.response

  } catch (error) {
    return error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'

  }
}

export const getReport = async (data) => {
  try {

    const response = await db.post(`/report`, data,{
        responseType: 'blob'
  });

    return response

  } catch (error) {
    return error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'

  }
}


export const getReports = async () => {
  try {

    const response = await db.get(`/reports`)
    return response?.data?.data || response.data.response

  } catch (error) {
    return error.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.'

  }
}
