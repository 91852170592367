import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { sendPasswordRestored } from '../../context/users/UserActions'
import { useUserContext } from '../../context/users/UserContext'
import Logo from '../layout/assets/logo.png'

// eslint-disable-next-line
export default function (props) {
  const { setAlert } = useUserContext()
  const navigate = useNavigate()
  const params = useParams()

  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })

  const fetchData = async () => {
    const getRestoredPass = async () => {
      const response = await sendPasswordRestored(params.userToken)
      if(typeof response === 'string') {
        if (response.includes('Hemos enviado tu nueva contraseña al correo:')) {
          const email = response.replace('Hemos enviado tu nueva contraseña al correo: ', '')
          setAlert({
            message: `Contraseña actualizada exitosamente. Hemos enviado un correo a ${email} con tu nueva contraseña.`,
            msgType: 'success'
          })
        } else {
          setAlert({
            message: `${response}`,
            msgType: 'warning'
          })
        }
      } else {
        setAlert({
          message: response.message,
          msgType: 'warning'
        })
      }
    }

    getRestoredPass()
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className='w-full mx-auto lg:w-10/12'>
      <div className='text-center hero-content'>
        <div className='w-100 xl:w-80 lg:w-80 md:w-80 sd:w-80'> 
          <img
            className='text-center mx-auto'
            src={Logo}
            alt='Loading...'
          />
        </div>

        <div className='w-100 xl:w-80 lg:w-80 md:w-80 sd:w-80 mt-4'> 
          <button onClick={() => navigate(`/`)} className='btn btn-primary btn-block mb-3'>
            Ingresar a Alleanza
          </button>
        </div>
      </div>
    </div>
  )
}
