import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getByIdPA, currencyFormat } from '../../context/users/UserActions'
import { useUserContext } from '../../context/users/UserContext'
import { Card } from 'react-bootstrap'

// eslint-disable-next-line
export default function (props) {
  const { user: plan, authenticatedUser, setUser } = useUserContext()
  const params = useParams()

  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })

  const fetchData = async () => {
    const response = await getByIdPA({ path: 'plans', id: params.id, token: authenticatedUser.value })
    setUser(response)
  }
  const {
    // planId,
    planName,
    price,
    maxGuests,
    months,
    description,
    // createdBy,
    createdOn,
    // updatedBy,
    // updatedOn,
    } = plan

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className='gap-1 mt-3'>
        <div className='container'>
          <Card style={{ width: '30%' }}>
            <Card.Body>
              <Card.Title>{planName}</Card.Title>
              <Card.Subtitle className='mb-2 text-muted'>{description}</Card.Subtitle>
              <p className='text-800'>
                {price && (<><b>Precio:</b> {currencyFormat({ number: price})}<br /></>)}
                {maxGuests && (<><b>Max. Invitados:</b> {maxGuests}<br /></>)}
                {months && (<><b>Meses:</b> {months}<br /></>)}
                {description && (<><b>Descripción:</b> {description}<br /></>)}
                {createdOn && (<><b>Fecha de Registro:</b> {createdOn}<br /></>)}
              </p>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  )
}
