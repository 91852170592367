import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { useUserContext } from '../../context/users/UserContext'
import {FaPen, FaTimes} from 'react-icons/fa';
import {
  getQuestions,
  removeQuestion
} from "../../context/questions/QuestionActions";
import Select from "react-select";
import {getCategories, getPhotos} from "../../context/photos/PhotoActions";

// eslint-disable-next-line
export default function QuestionsList() {

  const navigate = useNavigate()
  const { authenticatedUser, setAlert } = useUserContext()

  const [questions, setQuestions] = useState([])
  const [desc, setDesc] = useState(new Set());
  const [selectedOption, setSelectedOption] = useState({ value: 0 });
  const [pag, setPag] = useState(1);
  const [maxNextPage, setMaxNextPage] = useState(0);

  window.scrollTo({
    top: 0,
    behavior: 'auto',
  })


  const fetchData = async () => {
    const response = await getQuestions({ path: 'questions', token: authenticatedUser.value, page: pag, pageSize: 10 });

    if (response.error) {
      setQuestions([]);
    } else {
      setQuestions(response.data);
      const descriptions = new Set(response.data.map(x => x.description ));
      setDesc(descriptions)
      const primerElemento = Array.from(descriptions)[0];
      setSelectedOption({ label: primerElemento, value: primerElemento });

      const count = response.paging.totalRecords;
      if (count > 0) {
        let number = Math.ceil(count / 10);
        setMaxNextPage(number);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption)
  };

  //page
  const nextPage = () => {
    if ((pag ) < maxNextPage) {
      // console.log(pag + " < " + maxPage);
      setPag(pag + 1);
    }
  };

  const prevPage = () => {
    if (pag !== 1) {
      setPag(pag - 1);
    }
  };

  useEffect(() => {
    let photosFilter = questions.filter(photo => photo.description === selectedOption.value);
    setQuestions(photosFilter);

  }, [selectedOption]);

  useEffect(() => {
    const getPictures = async () => {
      const response = await getQuestions({ path: 'questions', token: authenticatedUser.value, page: pag, pageSize: 10 });
      setQuestions(response.data);

      const descriptions = new Set(response.data.map(x => x.description ));
      setDesc(descriptions)
      const primerElemento = Array.from(descriptions)[0];
      setSelectedOption({ label: primerElemento, value: primerElemento });
    };

    getPictures();
  }, [pag]);

  const handleQuestionDelete = async (id, hasSessions) => {

    if(!hasSessions) {
      let response = await removeQuestion({ path: 'questions',
        id: id,
        data: {
          deleted: true,
        },
        token: authenticatedUser.value
      })

      if(response) {
        setQuestions(questions.filter((question) => question.questionId !== id ))
        setAlert({
          message: `Pregunta borrada exitosamente`,
          msgType: 'success'
        })
      }
    } else {
      setAlert({
        message: 'Esta pregunta no se puede eliminar porque tiene registros asociados',
        msgType: 'warning'
      })
    }
  }

  const opts = Array.from(desc).map(categoryName => ({ label: categoryName, value: categoryName }));;

  return (
    <>
      <div className='container mt-3'>
        <div className=''>
          <div className='row'>
            <div className='col-2'>
              <h5 className='card-title'>Preguntas</h5>
            </div>
            <div className='col-4'>
              <button onClick={() => navigate(`/preguntas/agregar`)} type='submit' className='btn btn-primary btn-block mb-3'>
                + Agregar Pregunta
              </button>
            </div>
            <div className='col  mb-3 d-flex justify-content-end'>
              <Select
                  className='mt-2  w-50  '
                  value={selectedOption}
                  onChange={handleSelectChange}
                  options={opts}
                  isSearchable={true}
                  placeholder='Buscar por categoría'
              />
            </div>

          </div>
          <table className='table table-hover table-bordered'>
            <thead>
              <tr>
                <th scope='col'>Id</th>
                <th scope='col'>Pregunta</th>
                <th scope='col'>Descripción</th>
                {/*<th scope='col'>Tipo</th>*/}
                <th scope='col'></th>
                <th scope='col'></th>
              </tr>
            </thead>
            <tbody>
              {questions && questions.map((question, index) => (
                <tr key={question.questionId}>
                  <th scope='row'>{question.questionId}</th>
                  <td>{question.question}</td>
                  <td>{question.description}</td>
                  {/*<td>{question.type || '-'}</td>*/}
                  <td><Link to={`/preguntas/${question.questionId}/actualizar`}> <FaPen className='amount-icon' color={'black'}/></Link></td>
                  <td>
                    <button
                      onClick={() => handleQuestionDelete( question.questionId, question.hasSessions)}
                      className='btn'
                      data-bs-title='This top tooltip is themed via CSS variables.'>
                        <FaTimes className='amount-icon' />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

        </div>

        <nav aria-label="" className={"d-flex justify-content-end title-primary"}>
          <ul className="pagination">
            <li className="page-item" onClick={prevPage}>
              <a className="page-link title-primary fs-3" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
                <span className="sr-only">Previous</span>
              </a>
            </li>
            {/*{[...Array(maxPage)].map((_, index) => (*/}
            {/*    <li key={index + 1} className={`page-item ${pag === (index + 1) ? 'btn-primary title-primary ' : ''}`} value={index + 1}>*/}
            {/*      <a className={`page-link ${pag === (index + 1) ? 'btn-primary  text-white' : 'title-primary'}`} onClick={() => setPag(index + 1)}>*/}
            {/*        {index + 1}*/}
            {/*      </a>*/}
            {/*    </li>*/}
            {/*))}*/}
            <li className="page-item" onClick={nextPage}>
              <a className="page-link title-primary fs-3" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
                <span className="sr-only">Next</span>
              </a>
            </li>
          </ul>
        </nav>

      </div>

    </>
  )
}
