import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { addPA, searchPA } from '../../context/users/UserActions'
import { useUserContext } from '../../context/users/UserContext'

// eslint-disable-next-line
export default function (props) {
  const navigate = useNavigate()
  const { activeSessions, authenticatedUser, authenticateUser, setToken, setAlert, setActiveSessions } = useUserContext()

  const [sessionName, setSessionName] = useState('')
  const [description, setDescription] = useState('')

  const handleChangeSessionName = (e) => setSessionName(e.target.value)
  const handleChangeDescription = (e) => setDescription(e.target.value)

  const fetchData = async () => {
    const response = await searchPA({ path: 'sessions', token: authenticatedUser.value })
    if (response.error || !Array.isArray(response.data)) {
      setAlert({
        message: response.message || 'Error en la transacción. Si persiste el error, por favor contacte a soporte.',
        msgType: 'warning'
      })
    } else {
      setActiveSessions(response.activeSession)
      // setAlert({
      //   message: 'El sistema por el momento no graba las sesiones.',
      //   msgType: 'warning'
      // })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if(sessionName === '') {
      setAlert({
        message: 'Por favor ingresa todos los datos requeridos',
        msgType: 'warning'
      })
    } else {
      let canCreate = true
      if(activeSessions) {
        canCreate = window.confirm('¿Seguro que deseas crear una nueva sesión? La sesión activa actual se cerrará, ya que solo puede haber una sesión activa a la vez.')
      }
      if(canCreate){
        // dispatch({ type: 'SET_LOADING' })

        let sessionData = await addPA({ path: 'sessions',
        data: {
          sessionName: sessionName.trim(),
          description: description || '',
        }, 
        token: authenticatedUser.value })

        if(Object.prototype.hasOwnProperty.call(sessionData, 'sessionCode')) {
          let userData = {
            ...authenticatedUser,
            sessionCode: sessionData.sessionCode,
          }
          authenticateUser(userData)
          setToken(authenticateUser.value)
          localStorage.setItem('alleanza_jwtoken', JSON.stringify(userData))
          localStorage.setItem('alleanza_currentSession', JSON.stringify({ sessionCode: sessionData.sessionCode }))
          window.open(`${window.location.origin}/sesion/${sessionData.sessionCode}?newSession=1`, "_blank")
          navigate('/sessions')
        } else {
          setAlert({
            message: sessionData.message,
            msgType: 'warning'
          })
        }
      }
    }
  }

  return (
    <>
      <div className=''>
        <form onSubmit={handleSubmit} className=''>
          <div className='Auth-form-content'>
            <div className='form-group text-center'>
              <h2>Registro de Sesión</h2>
            </div>

            {/* <h3 className='Auth-form-title'>Sign In</h3> */}
            <div className='form-group mt-3'>
              <label>Nombre de la Sesión: *</label>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='Nombre del Sesión'
                value={sessionName}
                onChange={handleChangeSessionName}
              />
            </div>

            <div className='form-group mt-3'>
              <label>Descripción:</label>
              <input
                type='text'
                className='form-control mt-1'
                placeholder='Descripción'
                value={description}
                onChange={handleChangeDescription}
              />
            </div>

            <div className='d-grid gap-2 mt-5'>
              <button type='submit' className='btn btn-primary btn-block mb-3'>
                REGISTRAR
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  )
}
