import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom';
import { useUserContext } from '../../context/users/UserContext'
import {FaFileExcel, FaPen, FaTimes} from 'react-icons/fa';
import {getReport, getReports} from "../../context/photos/PhotoActions";
import {getReportSession, getSessions} from "../../context/users/UserActions";

// eslint-disable-next-line
export default function ReportList() {

    const [reports, setReports] = useState()
    const [page, setPage] = useState(1)
    const [hasMore, setHasMore] = useState(true)
    const { users: sessions, authenticatedUser, setUsers, setAlert } = useUserContext()

    const [keywords, setKeywords] = useState('')
    const handleChangeKeywords = (e) => setKeywords(e.target.value)

    window.scrollTo({
        top: 0,
        behavior: 'auto',
    })

    useEffect(() => {
        const getPictures = async () => {
            const response = await getSessions({ path: 'sessions', token: authenticatedUser.value, page});
            setReports(response.data)
            setHasMore(response.paging.hasMore)
        }
        getPictures()
    }, [page])


    const handleOnDownloadReport = async (sessionCode) => {
        const data = await getReportSession({ path: 'canvasReport', sessionCode, token: authenticatedUser.value })

        const response = await getReport(data.data);

        if (response.status === 200) {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'reporte.xlsx';
            link.click();
            window.URL.revokeObjectURL(url);
        }
    }

    const nextPage = () => {
        if(hasMore){
            setPage(page + 1)
        }
    }
    const prevPage = () => {
        if(page > 1 ){
            setPage(page - 1)
        }
    }

    return (
        <>
            <div className='gap-1 mt-3'>
                <div className='container'>

                    <div className='row'>
                        <div className='col-2'>
                            <h5 className='card-title '>Reportes</h5>
                        </div>

                    </div>
                    <table className='table table-hover table-bordered  mt-3'>
                        <thead>
                        <tr>
                            <th scope='col'>Sesión ID</th>
                            <th scope='col'>Nombre de la sesión</th>
                            <th scope='col'>Fecha y hora de la sesión</th>
                            <th scope='col'>Descargar</th>
                        </tr>
                        </thead>
                        <tbody>
                        {reports && reports.length > 0 && reports.map((report, index) => (
                            <tr key={report.sessionId}>
                                <th scope='row'>{report.sessionCode}</th>
                                <td >
                                    {report.sessionName}
                                </td>
                                <td>{report.sessionDate}</td>
                                <td>
                                    <button
                                        onClick={() => handleOnDownloadReport(report.sessionCode)}
                                        className='btn'
                                        data-bs-title='This top tooltip is themed via CSS variables.'>
                                        <FaFileExcel />
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>

                    <nav aria-label='Page navigation example' className={'d-flex justify-content-end title-primary'}>
                        <ul className='pagination'>
                            {page > 1 ? (
                                <li className='page-item' onClick={prevPage}>
                                    <a className='page-link title-primary fs-3' aria-label='Previous'>
                                        <span aria-hidden='true'>&laquo;</span>
                                        <span className='sr-only'>Previous</span>
                                    </a>
                                </li>
                            ) : (
                                <li className='page-item' onClick={prevPage}>
                                    <a className='page-link title-disable fs-3' aria-label='Previous'>
                                        <span aria-hidden='true'>&laquo;</span>
                                        <span className='sr-only'>Previous</span>
                                    </a>
                                </li>
                            )}

                            {hasMore ? (
                                <li className='page-item ' onClick={nextPage}>
                                    <a className='page-link title-primary fs-3' aria-label='Next'>
                                        <span aria-hidden='true'>&raquo;</span>
                                        <span className='sr-only'>Next</span>
                                    </a>
                                </li>
                            ) : (
                                <li className='page-item ' onClick={nextPage}>
                                    <a className='page-link title-disable fs-3' aria-label='Next'>
                                        <span aria-hidden='true'>&raquo;</span>
                                        <span className='sr-only'>Next</span>
                                    </a>
                                </li>
                            )}
                        </ul>
                    </nav>
                </div>

            </div>
        </>
    )
}
